import React, { Component } from 'react';
import "react-tiger-transition/styles/main.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Redirect } from 'react-router';
import { Navigation, Route, Link, glide } from "react-tiger-transition";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import PullToRefresh from 'rmc-pull-to-refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
// npm i rmc-pull-to-refresh

import Config from '../../config';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import TextField from '@material-ui/core/TextField';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DatePicker from 'react-date-picker';

import DeviceTypeConfiguration from './DeviceTypeConfiguration';
import FormAddDeviceConfiguration from './FormAddDeviceConfiguration';
import SchemeCANBus from './schemesCANBus/SchemeCANBus';
import ManagementManuals from './ManagementManuals/ManagementManuals';

import CheckTachograph from './checkups/CheckTachograph';
import LvCan from './checkups/LvCan';



import { Line } from 'react-chartjs-2';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

import CameraIcon from '@material-ui/icons/CameraAlt';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorIcon from '@material-ui/icons/Error';

import Grid from '@material-ui/core/Grid';

import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import BeatLoader from 'react-spinners/BeatLoader';
import TaskOrders from './InfoCRM/TaskOrdersRoutes';
import TaskOrderDetail from './InfoCRM/TaskOrderDetail';

import BarcodeScanner2 from '../common/scanner/BarcodeScanner2';
import CardList from './Common/CardList';


const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 760,
        backgroundColor: theme.palette.background.paper,
        padding: "20px",
        margin: "auto",
    },
    root2: {
        width: '100%',
        paddingTop: "15px",
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root2': {
            margin: theme.spacing(1),
            width: '25ch',
        }
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    formControlHistorical: {
        // margin: theme.spacing(1),
        minWidth: "100%",
        fontSize: "14px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        fontSize: "14px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    listItem: {
        '& .MuiListItemText-secondary': {
            fontSize: theme.typography.fontSize,
        }
    },
    textFieldDialog: {
        '& > *': {
            fontSize: "14px",
            letterSpacing: "0px"
        },
    }
});

const optionsLayout = {
    responsive: true
}

const spinnerCss = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-30px'
}
glide({
    name: 'glide-left'
});
glide({
    name: 'glide-right',
    direction: 'right'
});
const userDataInfo = JSON.parse(localStorage.getItem("userDataInfo"));
// document.getElementById("root").style.height = "100vh";

class InstaladoresCheckControl extends Component {
    static defaultProps = {
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSySEDDFRGFQSw2OjzDEE1-tDsN7vw&v=3.exp&libraries=geometry,drawing,places",
    }

    constructor() {
        super(...arguments);
        this.state = {
            optionsObjs: [
                {
                    "id": "contacto",
                    "label": "Contacto puesto / quitado",

                },
            ],
            verificationsOptions: {
                "contact": 0,
                "inputDigital": 0,
                "outputDigital": 0,
                "location": 0,
                "iButton": 0,
                "tacografo": 0,
                "temperature": 0,
                "lvcan": 0,
                "fms": 0,
                "tachoDownload": 0
            },
            verificationsOptionsView: {
                "contact": 0,
                "inputDigital": 0,
                "outputDigital": 0,
                "location": 0,
                "iButton": 0,
                "tacografo": 0,
                "temperature": 0,
                "lvcan": 0,
                "fms": 0,
                "tachoDownload": 0
            },
            serialNumber: "",
            alias: "",
            deviceNumberPlate: "",
            redirect: false,
            option: "",
            message: "",
            snackbarOpen: false,
            valueState: 0,
            isOpenDialog: false,
            valueDate: new Date(),
            deviceCheckControlId: "",
            action: -2,
            historicalDateStart: new Date(),
            historicalDateEnd: new Date(),
            valueComboHistorical: "",
            historicalSerialNumber: "",
            responseCheckControl: {},
            redirectHistory: false,
            redirectToVerificationPage: false,
            listDataHistory: [],
            itemListToView: {},
            isOpenDialogFinalizeCheckControl: false,
            dateFirstTime: "04/03/2021",
            responseEvents: [],
            refreshing: false,
            dataTemperatureFromApiP72: [],
            dataTemperatureFromApiP73: [],
            dataTemperatureFromApiP74: [],
            counterTemperatures: "0/0",
            objMap: {
                lat: 0,
                lng: 0,
                lastGPSPositionDate: this.formatDate(new Date())
            },
            objCanBus: {},
            isShowCharts: {},
            isShowChartsTemperature: {},
            deviceConfigurationSelected: "",
            lvCanButtonIsDisable: true,
            listTacografo: [],
            valueTextFieldLvCan: null,
            listLvcan: [],
            typesDevicesConfiguration: [],
            parametersDevicesConfiguration: [],
            isOpenSnackbarSuccess: false,
            textSnackBarSuccess: "",
            isLoading: false,
            tachoDownload: 0,
            IsDeviceTachographDownloadEnabled: false,
            iButtonConfiguration: 0,
            boolDisabledButtonSonda: true,
            sondaTemperature: "",
            errorSonda: false,
            errorSondaText: "",
            scannerActive: false,
            dataDigitalInput: {
                din2: {
                    name: "",
                    events: []
                },
                din3: {
                    name: "",
                    events: []
                },
                din4: {
                    name: "",
                    events: []
                }
            },
            dataDigitalOutput: {
                douT1: {
                    name: "",
                    events: [],
                    countdown: 0,
                    isButtonDisabled: false,
                    status: "Desactivado"
                },
                douT2: {
                    name: "",
                    events: [],
                    countdown: 0,
                    isButtonDisabled: false,
                    status: "Desactivado"
                },
                douT3: {
                    name: "",
                    events: [],
                    countdown: 0,
                    isButtonDisabled: false,
                    status: "Desactivado"
                },
                douT4: {
                    name: "",
                    events: [],
                    countdown: 0,
                    isButtonDisabled: false,
                    status: "Desactivado"
                },
            },

            validate: false
        }


    }

    componentDidMount() {
        // this.onHandleChangeDatePickerEnd(new Date());
        this.onHandleDatePickerStart(new Date(), new Date());
        let coming = "";
        let serialNumber = 0;

        if (this.props.location.state === undefined) {
            coming = localStorage.getItem("coming");
            serialNumber = localStorage.getItem("serialNumber");
        } else {
            coming = this.props.location.state.coming
            serialNumber = this.props.location.state.serialNumber
        }
        if (coming !== undefined) {
            this.setState({ serialNumber: serialNumber }, () => {
                //console.log(this.state.serialNumber)
                this.verifySerialNumber();
            });

        }

        // if (this.props.location.state === undefined) {
        //     const userDataInfo = localStorage.getItem("userDataInfo");
        //     this.props.history.replace({
        //         ...this.props.selecteOt,
        //         ...this.props.history.location,
        //         state: {
        //             ...this.props.history.location.state,
        //             userDataInfo: JSON.parse(userDataInfo),

        //         },
        //     });
        // }
    }

    handleOnChange(e) {
        this.setState({
            serialNumber: e.target.value,
            option: "contact"
        });
    }

    handleOnChangeAlias(e) {
        this.setState({
            alias: e.target.value,
        });
    }

    handleOnChangeSerialNumberHistorical(e) {
        this.setState({
            historicalSerialNumber: e.target.value,
            serialNumber: e.target.value
        });
    }

    toggleScanner = () => {
        this.setState(prevState => ({
            scannerActive: !prevState.scannerActive
        }));
    }

    setSerialNumberFromScan = (e) => {
        this.setState({
            serialNumber: e.codeResult.code,
            scannerActive: false
        });
    }

    verifyState = () => {
        let optionValue = "";
        console.log(this.state.option);
        if (this.state.option === "contact") {
            optionValue = "contact"
        } else if (this.state.option === "location") {
            optionValue = "location"
        } else if (this.state.option === "iButton") {
            optionValue = "iButton"
        } else if (this.state.option === "tacografo") {
            optionValue = "tacografo"
        } else if (this.state.option === "temperature") {
            optionValue = "temperature"
        } else if (this.state.option === "lvcan") {
            optionValue = "lvcan"
        } else if (this.state.option === "fms") {
            optionValue = "fms"
        } else if (this.state.option === "inputDigital") {
            optionValue = "inputDigital"
        }else if (this.state.option === "outputDigital") {
            optionValue = "outputDigital"
        }

        if (this.state.valueState === 0) {
            this.setState({
                verificationsOptions: {
                    ...this.state.verificationsOptions,
                    [optionValue]: 0
                },
                valueState: 0,
            }, () => {
                this.putActiveCheck(2, this.state.deviceCheckControlId);
                this.redirect2();
            })
        } else if (this.state.valueState === 1) {
            this.setState({
                verificationsOptions: {
                    ...this.state.verificationsOptions,
                    [optionValue]: 1
                },
                valueState: 0,
            }, () => {
                this.putActiveCheck(2, this.state.deviceCheckControlId);
                this.redirect2();
            })
        } else if (this.state.valueState === 2) {
            // console.log("entra");
            this.setState({
                verificationsOptions: {
                    ...this.state.verificationsOptions,
                    [optionValue]: 2

                },
                valueState: 0,

            }, () => {
                this.putActiveCheck(2, this.state.deviceCheckControlId);
                this.redirect2();
            })
        } else if (this.state.valueState === 3) {
            // console.log("entra");
            this.setState({
                verificationsOptions: {
                    ...this.state.verificationsOptions,
                    [optionValue]: 3

                },
                valueState: 0,

            }, () => {
                this.putActiveCheck(2, this.state.deviceCheckControlId);
                this.redirect2();
            })
        }
    }

    async saveDataSondaTemperature(param, event) {
        let objectToSave = {};
        objectToSave = {
            sonda: this.state.sondaTemperature,
            numeroSerie: this.state.serialNumber,
            tipo: param
        };

        const settings = {
            method: 'POST',
            headers: {
                'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objectToSave)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        const fetchResponse = await fetch(`${Config.apiGateway.URL}Sensors`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    await fetchResponse.json();
                    this.setState({
                        message: "Sensor actualizado correctamente.",
                        snackbarOpen: true
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            // La respuesta no es exitosa
            if (fetchResponse.status === 400) {
                // Manejar el bad request aquí
                //alert(await fetchResponse.json());
                this.setState({
                    message: await fetchResponse.json(),
                    snackbarOpen: true
                });
            } else {
                // Manejar otros códigos de estado aquí
                console.log('Otro error:', fetchResponse.status);
            }
        }
    }

    showMessageTachoSend = () => {
        this.setState({
            message: "Tachocheck enviado correctamente.",
            snackbarOpen: true
        });
    }

    showMessageDownloadSend = () => {
        this.setState({
            message: "La descarga se ha enviado, solo habría que esperar al que vehículo responda.",
            snackbarOpen: true
        });
    }

    async verifySerialNumber(e, option) {
        if (this.state.serialNumber === "0" || this.state.serialNumber === "") {
            this.setState({
                message: "Debe al menos introducir un número de serie",
                snackbarOpen: true
            });
            return;
        } else {
            // if (this.props.location.state.coming !== undefined) {
            //     const response = await this.getActiveCheck();
            //     if (response.length === 0) {
            //         await this.addNewRegisterSerialNumber();
            //         await this.getActiveCheck();
            //     }


            //     //this.redirectToActive();
            // } else {

            //     await this.getActiveCheck();
            // }
            this.setState({
                isLoading: true
            });
            const response = await this.getActiveCheck();

            if (response.length === 0) {
                await this.addNewRegisterSerialNumber();
                await this.getActiveCheck();
            }
            this.setState({
                isLoading: false
            });
        }
    }

    handleOnChangeLocation(e) {
        this.setState({
            valueState: e.target.value,
            option: "location"
        });
    }

    handleOnChangeiButton(e) {
        this.setState({
            valueState: e.target.value,
            option: "iButton"
        });
    }

    handleOnChangeiButtonConfiguration(e) {
        this.setState({
            iButtonConfiguration: e.target.value
        });
    }

    handleOnChangeTacografo = (e) => {
        this.setState({
            valueState: e.target.value,
            option: "tacografo"
        });
    }

    handleOnChangeTacografoDownload = (e) => {
        this.setState(prevState => ({
            verificationsOptions: {
                ...prevState.verificationsOptions,
                tachoDownload: e.target.value
            }
        }));
    }

    handleOnChangeTemperature(e) {
        this.setState({
            valueState: e.target.value,
            option: "temperature"
        });
    }

    validateTextSonda(email) {
        const re = /^[a-zA-Z0-9]{12}$/;
        return re.test(String(email));
    }

    validateData() {
        if (this.state.selectedValue === "email") {
            var error = "";
            if (!this.validateEmail(this.state.email)) {
                error = "Su sonda no tiene el formato correcto.";

                this.setState({
                    errorEmailText: error,
                    errorEmail: true
                });
                return;
            }

            this.setState({
                errorEmailText: "",
                errorEmail: false
            });
        }
        if (this.props.selectedObj.length > 0) {
            this.editData();
        } else {
            this.saveData();
        }

    }

    handleOnChangeSondaTemperatura = (e) => {
        const newValue = e.target.value.toUpperCase();
        let error = '';


        // Crea un objeto temporal para almacenar los cambios de estado
        const newState = {
            sondaTemperature: newValue,
            boolDisabledButtonSonda: true,
            errorSonda: false,
            errorSondaText: ''
        };

        if (newValue.length <= 0) {
            this.setState(newState);
            return;
        }

        if (this.validateTextSonda(newValue)) {
            newState.boolDisabledButtonSonda = false;
        } else {
            error = "Su sonda / sensor no tiene el formato correcto.";
            newState.errorSondaText = error;
            newState.errorSonda = true;
        }

        this.setState(newState);
    }

    handleOnChangeLvcan = (e) => {
        this.setState({
            valueState: e.target.value,
            option: "lvcan"
        });
    }

    handleOnChangeFms(e) {
        this.setState({
            valueState: e.target.value,
            option: "fms"
        });
    }

    redirectToActive() {
        this.setState({
            // serialNumber: "0",
            redirect: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    redirect: false,
                    isOpenDialog: false
                })
            }, 0);
        });

    }

    handleAcceptDialogFinalizeCheckControl() {
        this.finalizeCheckControl();
    }

    handleCloseDialogFinalizeCheckControl() {
        this.setState({
            isOpenDialogFinalizeCheckControl: false
        });
    }

    redirect() {
        // this.putActiveCheck(1, this.state.responseCheckControl.deviceCheckControlId);

        this.onAcceptDialog();
        localStorage.removeItem("douT2");
        this.setState({
            // serialNumber: "0",
            redirect: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    redirect: false,
                    isOpenDialog: false
                })
            }, 0);
        });
    }

    redirect2() {
        this.setState({
            redirect: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    redirect: false
                })
            }, 0);
        });
    }

    redirectToVerificationPage() {
        this.setState({
            redirectToVerificationPage: true,
            isLoading: false,

            verificationsOptions: {
                "contact": 0,
                "inputDigital": 0,
                "outputDigital": 0,
                "location": 0,
                "iButton": 0,
                "tacografo": 0,
                "temperature": 0,
                "lvcan": 0,
                "fms": 0,
                "tachoDownload": 0
            }
        }, () => {
            setTimeout(() => {
                this.setState({
                    redirectToVerificationPage: false
                })
            }, 0);
        });
    }

    handleClose() {
        this.setState({
            snackbarOpen: false
        });
    }

    handleChangeComboBoxContact(e) {
        this.setState({
            valueState: e.target.value,

            option: "contact"
        });
    }

    handleChangeComboBoxInputDigital(e) {
        this.setState({
            valueState: e.target.value,
            option: "inputDigital"
        });
    }

    handleChangeComboBoxOutputDigital(e) {
        this.setState({
            valueState: e.target.value,
            option: "outputDigital"
        });
    }

    // actividad
    handleCloseDialog() {
        this.setState({
            isOpenDialog: !this.state.isOpenDialog,
            isOpenDialogFinalizeCheckControl: false,
            listTacografo: [],
            listLvcan: []
        }, () => {

            if (this.props.location && this.props.location.state && this.props.location.state.coming !== undefined) {
                if (!this.state.isOpenDialog) {
                    this.props.history.push({
                        pathname: '/dashboard/ordenes-trabajo/detail/Activity',
                        selectedOt: this.props.location.state.selectedOt === false ? this.props.location.selectedOt : this.props.location.state.selectedOt,
                        status: this.props.location.state.status,
                        state: {
                            userDataInfo: userDataInfo
                        },
                    });
                }
            }
        })
        // if (this.state.isOpenDialog) {
        //     this.props.history.push({
        //         pathname: '/dashboard/ordenes-trabajo/detail/Activity',


        //     });
        // }
    }

    onAcceptDialog() {

        this.setState({
            isOpenDialog: false,
            dateFirstTime: this.formatDate(new Date())
        }, () => {
            this.putActiveCheck(this.state.action, this.state.deviceCheckControlId)
        });
    }

    async addNewRegisterSerialNumber() {
        if (this.state.serialNumber === "0") {
            this.setState({
                message: "Debe indiciar como minímo un número de serie.",
                snackbarOpen: true
            });
            return;
        }
        // config.apiGateway.URL
        let response = "";
        const settings = {
            method: 'POST',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl?serialNumber=${this.state.serialNumber}&deviceAlias=${this.state.alias}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.text();
                    if (this.props.location && this.props.location.state && this.props.location.state.coming !== undefined) {
                        if (response > "0" && response < "500") {
                            this.setState({
                                message: "Añadido correctamente.",
                                snackbarOpen: true
                            });
                        } else if (response === "-1") {
                            this.setState({
                                message: "Registro ya existente.",
                                snackbarOpen: true
                            });
                        } else if (response === "500") {
                            this.setState({
                                message: "Error interno en el servidor (500)",
                                snackbarOpen: true
                            });
                        }
                    }

                    return response;
                } catch (error) {
                    return error;
                    // console.log(error);
                }
            }
        } else {
            // this.goToPageErr();
            // return fetchResponse.status;
            alert("HTTP-Error: " + fetchResponse.status);
        }

    }

    async getActiveCheck() {
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl?serialNumber=${this.state.serialNumber}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();
                    const defaultValues = {
                        "contact": 0,
                        "inputDigital": 0,
                        "outputDigital": 0,
                        "location": 0,
                        "iButton": 0,
                        "tacografo": 0,
                        "temperature": 0,
                        "lvcan": 0,
                        "fms": 0,
                        "tachoDownload": 0
                    };

                    response.forEach(element => {
                        if (element.checkControl !== "") {
                            // Combinamos los objetos correctamente
                            let json = JSON.parse(element.checkControl);
                            let combined = { ...defaultValues, ...json };
                            element.checkControl = combined;
                        }
                    });


                    if (response.length === 0) {
                        // console.log("Entra");
                        if (this.props.location && this.props.location.state && this.props.location.state.coming !== undefined) {
                            this.setState({
                                message: "Ese número de serie no existe, lo añadiremos a continuación.",
                                snackbarOpen: true,
                                itemListToView: {}
                            });
                        }
                    } else if (response[0].startCheckControlDateUtc === null) {
                        this.setState({
                            deviceCheckControlId: response[0].deviceCheckControlId,
                            action: 1,
                            responseCheckControl: response[0],
                            itemListToView: {},
                            deviceNumberPlate: response[0].deviceNumberPlate,
                            IsDeviceTachographDownloadEnabled: response[0].isDeviceTachographDownloadEnabled,
                            ClosedByUser: response[0].ClosedByUser
                        });
                        this.handleCloseDialog();

                    } else {
                        this.setState({
                            deviceCheckControlId: response[0].deviceCheckControlId,
                            responseCheckControl: response[0],
                            verificationsOptions: response[0].checkControl !== "" ? response[0].checkControl : this.state.verificationsOptions,
                            itemListToView: {},
                            deviceNumberPlate: response[0].deviceNumberPlate,
                            IsDeviceTachographDownloadEnabled: response[0].isDeviceTachographDownloadEnabled,
                            ClosedByUser: response[0].ClosedByUser
                        }, () => this.redirectToActive());
                    }
                    return response;
                } catch (error) {

                    return error;
                    // console.log(error);
                }
            }
        } else {
            // this.goToPageErr();
            // return fetchResponse.status;
            this.setState({
                message: "Ha ocurrido un error interno.",
                snackbarOpen: true,
                itemListToView: {},
                isLoading: false
            });
            alert("HTTP-Error: " + fetchResponse.status);
            return;
        }
    }

    async getHistorical() {
        let response = "";
        let dateStart = this.state.historicalDateStart.toJSON();
        let dateEnd = this.state.historicalDateEnd.toJSON();
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/History?serialNumber=${this.state.historicalSerialNumber}&startDate=${dateStart}&endDate=${dateEnd}&period=${this.state.valueComboHistorical}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();

                    // let newResponse = [];
                    if (response.length === 0) {
                        this.setState({
                            message: "No se han encontrado registros para el número de serie indicado.",
                            snackbarOpen: true
                        });
                        return;
                    }

                    response.forEach(element => {
                        element.checkControl = JSON.parse(element.checkControl);
                    });

                    this.setState({
                        redirectHistory: true,
                        listDataHistory: response,
                        historicalSerialNumber: ""
                    }, () => {
                        this.setState({
                            redirectHistory: false
                        });
                    });
                    // this.props.history.push({
                    //     pathname: '/dashboard/historico/listado',
                    //     stateProps: this.state

                    // });
                    return response;
                } catch (error) {
                    return error;
                    // console.log(error);
                }
            }
        } else {
            // this.goToPageErr();
            // return fetchResponse.status;
            alert("HTTP-Error: " + fetchResponse.status);
            return;
        }
    }

    putActiveCheck = async (action, deviceCheckControlId) => {
        // config.apiGateway.URL
        let response = "";
        let copyState = { ...this.state.verificationsOptions }

        let objectToSend = {
            DeviceCheckControlId: deviceCheckControlId,
            CheckControl: JSON.stringify(copyState),
            DeviceNumberPlate: this.state.deviceNumberPlate,
            SerialNumber: this.state.serialNumber
        };
        const settings = {
            method: 'PUT',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objectToSend)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl?action=${action}&userId=${userDataInfo.userId}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.text();


                    return response;
                } catch (error) {
                    return error;
                    // console.log(error);
                }
            }
        } else {
            // this.goToPageErr();
            // return fetchResponse.status;
            alert("HTTP-Error: " + fetchResponse.status);
        }
    }

    onHandleChangeDatePickerStart(e) {
        this.setState({
            historicalDateStart: e
        });
    }

    onHandleChangeDatePickerEnd(e) {
        let date = e;
        date.setHours(23, 59, 59, 999);
        this.setState({
            historicalDateEnd: e
        });
    }

    onHandleDatePickerStart(start, end) {
        let dateEnd = end;
        dateEnd.setHours(23, 59, 59, 999);
        // this.setState({

        // });
        let dateStart = start;
        dateStart.setHours(0, 0, 0, 0);
        this.setState({
            historicalDateStart: dateStart,
            historicalDateEnd: dateEnd
        });
    }

    handleChangeComboBoxHistorical(e) {
        this.setState({
            valueComboHistorical: e.target.value,

        });
    }

    onClickFinalizeCheckControl() {
        this.setState({
            isOpenDialogFinalizeCheckControl: true
        });
    }

    finalizeCheckControl = async () => {
        // /api/Installers/ActivityState
        this.putActiveCheck(3, this.state.responseCheckControl.deviceCheckControlId);

        if (this.props.location && this.props.location.state && this.props.location.state.coming !== undefined) {
            await this.changeStatusActivityCRM();
            this.props.history.push({
                pathname: '/dashboard/ordenes-trabajo/detail/Activity',
                state: {
                    userDataInfo: userDataInfo
                },
            });
        } else {
            this.setState({
                isLoading: true,
                isOpenDialogFinalizeCheckControl: false,
            }, async () => {
                await this.getActivityCRM();
                this.redirectToVerificationPage();
            });
        }
    }

    changeStatusActivityCRM = async () => {
        try {
            //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT
            // const response = await fetch(`${Config.apiGateway.URL}Installers/PurchaseOrders?email=${props.location.state.userDataInfo.email}&status=approved`, {
            //   method: 'GET',
            //   // headers: {
            //   //   'Authorization': `Bearer ${token}`
            //   // }
            // });
            let activityId = localStorage.getItem("activityIdCRM");
            const requestBody = {
                activityId: activityId,
                status: "Completed"
            };
            const response = await fetch(`${Config.apiGateway.URL}Installers/ActivityState`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {

                await response.json();

            } else {
                console.error('Error en la respuesta:', response.status);
            }
        } catch (error) {
            console.error('Error en la llamada:', error);
        }
    }

    getDataSelectedList(requestCheckControlDateUtc) {

        const result = this.state.listDataHistory.filter(item => item.requestCheckControlDateUtc === requestCheckControlDateUtc);
        this.setState({
            itemListToView: result,
            deviceCheckControlId: result[0].deviceCheckControlId,
            verificationsOptions: result[0].checkControl,
            IsDeviceTachographDownloadEnabled: result[0].isDeviceTachographDownloadEnabled
        });
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }

    formatDate(date) {
        let time = new Date(date);
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
        let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
        let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
        // console.log(day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds);
        return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
    }

    setDateFirstTime() {
        return this.formatDate(new Date());
    }

    ////// EVENTOS API ///////
    async getIgnitionKeyCheck() {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        this.setState({
            refreshing: true,
            option: "contact"
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/IgnitionKey?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
            if (fetchResponse.status === 204) {
                this.setState({
                    refreshing: false
                });
                // this.goToPageErr();
                // return fetchResponse.status;
                return;
            }
            if (fetchResponse.ok) {
                if (fetchResponse !== null) {
                    try {
                        response = await fetchResponse.json();
                        this.setState({
                            isLoading: false
                        });
                        if (response.length >= 1 && response[0].endDate !== null) {
                            this.setState({
                                verificationsOptions: {
                                    ...this.state.verificationsOptions,
                                    "contact": 2
                                },
                                valueState: 2,
                                responseEvents: response,

                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        refreshing: false,
                                    }, () => {
                                        if (this.isEmpty(this.state.itemListToView)) {
                                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                                        }
                                    });
                                }, 1000);


                            });
                            // this.setState({

                            // });
                        } else {
                            this.setState({
                                responseEvents: response,
                                valueState: this.state.verificationsOptions.contact
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        refreshing: false
                                    });
                                }, 2000);
                            });
                        }

                    } catch (error) {
                        return error;
                        // console.log(error);
                    }
                }
            } else {
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });




    }

    async getIButtonCheck() {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        this.setState({
            refreshing: true,
            option: "iButton"
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/IButton?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
            if (fetchResponse.ok) {
                if (fetchResponse !== null) {
                    try {
                        response = await fetchResponse.json();
                        this.setState({
                            isLoading: false
                        });
                        if (response.length >= 1) {

                            this.setState({
                                verificationsOptions: {
                                    ...this.state.verificationsOptions,
                                    "iButton": 2
                                },
                                valueState: 2,
                                responseEvents: response
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        refreshing: false,
                                    }, () => {
                                        if (this.isEmpty(this.state.itemListToView)) {
                                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                                        }
                                    });
                                }, 2000);

                            });
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    responseEvents: response,
                                    refreshing: false,
                                    valueState: this.state.verificationsOptions.iButton
                                });
                            }, 500);

                        }

                    } catch (error) {
                        return error;
                        // console.log(error);
                    }
                }
                this.getIButtonParameter();
            } else {
                // this.goToPageErr();
                // return fetchResponse.status;
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });


    }

    async getInputDigitalCheck() {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        this.setState({
            refreshing: true,
            option: "inputDigital"
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/DigitalInputs?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
            if (fetchResponse.ok) {
                if (fetchResponse !== null) {
                    try {
                        response = await fetchResponse.json();
                        this.setState({
                            isLoading: false
                        });
                        this.setState(prevState => ({
                            dataDigitalInput: {
                                ...prevState.dataDigitalInput,
                                din2: {
                                    name: response.diN2.name,
                                    events: response.diN2.events
                                },
                                din3: {
                                    name: response.diN3.name,
                                    events: response.diN3.events
                                },
                                din4: {
                                    name: response.diN4.name,
                                    events: response.diN4.events
                                }
                            }
                        }));


                        if (response.diN2.events.length >= 1 || response.diN3.events.length >= 1 || response.diN4.events.length >= 1) {
                            this.setState({
                                verificationsOptions: {
                                    ...this.state.verificationsOptions,
                                    "inputDigital": 2
                                },
                                valueState: 2,
                                //responseEvents: response
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        refreshing: false,
                                    }, () => {
                                        if (this.isEmpty(this.state.itemListToView)) {
                                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                                        }
                                    });
                                }, 2000);

                            });
                            // this.setState({

                            // });
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    //responseEvents: response,
                                    refreshing: false,
                                    valueState: this.state.verificationsOptions.inputDigital
                                });
                            }, 500);

                        }

                    } catch (error) {
                        this.setState({
                            isLoading: false
                        });
                        return error;
                        // console.log(error);
                    }
                }
                //this.getIButtonParameter();
            } else {
                this.setState({
                    isLoading: false
                });
                // this.goToPageErr();
                // return fetchResponse.status;
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });
    }

    handleOnclickOutputDigitalUpdateSensors = async (sensorId) => {
        this.setState({ isLoading: true });

        const objectTosend = {
            deviceCheckControlId: this.state.deviceCheckControlId,
            dout: parseInt(sensorId)
        };

        const settings = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(objectTosend)
        };

        try {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/DigitalOutputs`, settings);

            if (fetchResponse.ok) {
                const registro = this.state.dataDigitalOutput["douT" + sensorId] || {};
                const hasFechaInicio = registro.fechaInicio !== null;
                const hasFechaFin = registro.fechaFin !== null;

                // Cambiar la lógica para habilitar el botón cuando ambas fechas estén presentes
                const isButtonDisabled = !(hasFechaInicio && hasFechaFin);

                this.setState((prevState) => ({
                    message: "Activación realizada correctamente.",
                    snackbarOpen: true,
                    isLoading: false,
                    dataDigitalOutput: {
                        ...prevState.dataDigitalOutput,
                        ["douT" + sensorId]: {
                            ...registro,
                            isButtonDisabled: isButtonDisabled,  // Aquí usamos la nueva lógica
                            countdown: isButtonDisabled ? 120 : 0 // Reinicia el temporizador solo si el botón está deshabilitado
                        }
                    }
                }), () => {
                    // Si ambas fechas están presentes, el temporizador debe iniciarse
                    if (hasFechaInicio && hasFechaFin) {
                        this.startCountdownWithEndDate(sensorId);
                    }
                });
            } else {
                console.error("Error al activar el sensor:", fetchResponse.status);
                this.setState({ isLoading: false });
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            this.setState({ isLoading: false });
        }
    };

    getOutputDigitalCheck = async () => {
        this.setState({ isLoading: true });
        const settings = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        try {
            const fetchResponse = await fetch(
                `${Config.apiGateway.URL}CheckControl/DigitalOutputs?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId)
                    ? this.state.responseCheckControl.deviceCheckControlId
                    : this.state.deviceCheckControlId
                }`,
                settings
            );

            if (!fetchResponse.ok) {
                throw new Error(`HTTP Error: ${fetchResponse.status}`);
            }

            const response = await fetchResponse.json();

            this.setState((prevState) => {
                const updatedDigitalOutput = {};

                ["douT1", "douT2", "douT3", "douT4"].forEach((key) => {
                    const data = response[key] || {};
                    const firstValidEvent = data?.events.find(
                        (event) => event.startDate && (!event.endDate || event.endDate === null)
                    );

                    const keyWithPrefix = `douT${key.charAt(3)}`;

                    // Si el temporizador está activo, conservar el estado actual
                    if (prevState.dataDigitalOutput[key]?.countdown > 0) {
                        updatedDigitalOutput[key] = {
                            ...prevState.dataDigitalOutput[key], // Conservar el estado actual
                            events: data.events || [], // Actualizar los eventos
                        };
                        return;
                    }

                    if (firstValidEvent) {
                        const startDate = new Date(firstValidEvent.startDate);
                        const now = new Date();
                        const remainingTime = Math.max(0, 120 - Math.floor((now - startDate) / 1000));

                        updatedDigitalOutput[key] = {
                            ...prevState.dataDigitalOutput[key],
                            events: data.events || [],
                            isButtonDisabled: remainingTime > 0, // Botón deshabilitado si el temporizador está activo
                            countdown: remainingTime > 0 ? remainingTime : "Activar", // Mostrar tiempo restante o "Activar"
                        };

                        // Si hay tiempo restante, inicia el temporizador
                        if (remainingTime > 0) {
                            this.startCountdownWithEndDate(key.replace("douT", ""), startDate);
                        }
                    } else {
                        // Si no hay eventos válidos, habilitar el botón y reiniciar el temporizador
                        updatedDigitalOutput[key] = {
                            ...prevState.dataDigitalOutput[key],
                            events: data.events || [],
                            isButtonDisabled: false,
                            countdown: "Activar",
                        };
                    }
                });

                return {
                    isLoading: false,
                    dataDigitalOutput: {
                        ...prevState.dataDigitalOutput,
                        ...updatedDigitalOutput,
                    },
                };
            }, () => {
                if (response.douT1.events.length >= 1 || response.douT2.events.length >= 1 || response.douT3.events.length >= 1 || response.douT4.events.length >= 1) {
                    this.setState({
                        verificationsOptions: {
                            ...this.state.verificationsOptions,
                            "outputDigital": 2
                        },
                        valueState: 2,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                refreshing: false,
                            }, () => {
                                if (this.isEmpty(this.state.itemListToView)) {
                                    this.putActiveCheck(2, this.state.deviceCheckControlId);
                                }
                            });
                        }, 2000);
                    });
                } else {
                    setTimeout(() => {
                        this.setState({
                            refreshing: false,
                            valueState: this.state.verificationsOptions.inputDigital
                        });
                    }, 500);
                }
            });
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false });
        }
    };

    // Función para iniciar el temporizador y actualizar el estado
    startCountdownWithEndDate = (sensorId, startDate) => {
        const key = `douT${sensorId}`;
        const currentTime = new Date().getTime();
        let remainingTime = 120; // Por defecto, 120 segundos

        // Si hay una startDate válida, recalcular el tiempo restante
        if (startDate) {
            const startDateTimestamp = new Date(startDate).getTime();
            const elapsedTime = (currentTime - startDateTimestamp) / 1000;
            remainingTime = Math.max(0, 120 - Math.floor(elapsedTime));
        }

        // Si el temporizador ya está activo, no reiniciar
        if (this.timers?.[key]) {
            return; // Si ya existe el temporizador, no lo reiniciamos
        }

        // Detener cualquier temporizador existente
        if (this.timers?.[key]) {
            clearInterval(this.timers[key]);
            delete this.timers[key];
        }

        // Iniciar el temporizador si queda tiempo
        if (remainingTime > 0) {
            this.setState((prevState) => ({
                dataDigitalOutput: {
                    ...prevState.dataDigitalOutput,
                    [key]: {
                        ...prevState.dataDigitalOutput[key],
                        isButtonDisabled: true,
                        countdown: remainingTime,
                    },
                },
            }), () => {
                // Guardar el temporizador en `this.timers`
                if (!this.timers) {
                    this.timers = {};
                }

                this.timers[key] = setInterval(() => {
                    this.setState((prevState) => {
                        const currentCountdown = this.state.dataDigitalOutput[key].countdown;

                        if (currentCountdown <= 1) {
                            clearInterval(this.timers[key]); // Limpiar el temporizador
                            delete this.timers[key];

                            return {
                                dataDigitalOutput: {
                                    ...prevState.dataDigitalOutput,
                                    [key]: {
                                        ...prevState.dataDigitalOutput[key],
                                        isButtonDisabled: false,
                                        countdown: "Activar",
                                    },
                                },
                            };
                        }

                        return {
                            dataDigitalOutput: {
                                ...prevState.dataDigitalOutput,
                                [key]: {
                                    ...prevState.dataDigitalOutput[key],
                                    countdown: currentCountdown - 1,
                                },
                            },
                        };
                    });
                }, 1000);
            });


        } else {
            // Si no queda tiempo, habilitar el botón inmediatamente
            this.setState((prevState) => ({
                dataDigitalOutput: {
                    ...prevState.dataDigitalOutput,
                    [key]: {
                        ...prevState.dataDigitalOutput[key],
                        isButtonDisabled: false,
                        countdown: "Activar",
                    },
                },
            }));
        }
    };

    async IButtonSendParameter() {
        // this.setState({
        //     isLoading: true
        // });
        //let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        //console.log(`${Config.apiGateway.URL}CheckControl/IButtonSend?serialNumber=${this.state.serialNumber}&mode=${this.state.iButtonConfiguration}`);

        this.setState({
            refreshing: true,
            //option: "iButton"
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/IButtonSend?serialNumber=${this.state.serialNumber}&mode=${this.state.iButtonConfiguration}`, settings);
            if (fetchResponse.ok) {
                await fetchResponse.json();
            } else {
                // this.goToPageErr();
                // return fetchResponse.status;
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });

        this.setState({ refreshing: false })
    }

    async getIButtonParameter() {
        // this.setState({
        //     isLoading: true
        // });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        this.setState({
            refreshing: true,
            //option: "iButton"
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/IButtonMode?serialNumber=${this.state.serialNumber}`, settings);
            if (fetchResponse.ok) {
                response = await fetchResponse.json();
                this.setState({
                    iButtonConfiguration: response
                });
            } else {
                // this.goToPageErr();
                // return fetchResponse.status;
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });


    }

    async getTemperatureCheck() {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        this.setState({
            refreshing: true
        }, async () => {
            const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Temperature?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
            if (fetchResponse.ok) {
                if (fetchResponse !== null) {
                    try {
                        response = await fetchResponse.json();
                        this.setState({
                            isLoading: false
                        });
                        let temperatures = [];
                        let temperatures73 = [];
                        let temperatures74 = [];

                        let isShowChartsTemperature = {
                            p72: false,
                            p73: false,
                            p74: false
                        }

                        let validatep72 = false;
                        let validatep73 = false;
                        let validatep74 = false;

                        response.forEach((element, index) => {
                            if (element.p72 !== null) {
                                temperatures.push({ x: index, y: element.p72 });
                                isShowChartsTemperature.p72 = true;
                            }
                            if (element.p73 !== null) {
                                temperatures73.push({ x: index, y: element.p73 });
                                isShowChartsTemperature.p73 = true;
                            }
                            if (element.p74 !== null) {
                                temperatures74.push({ x: index, y: element.p74 });
                                isShowChartsTemperature.p74 = true;
                            }



                            if (element.p72 !== null) {
                                if (element.p72 <= 50 && element.p72 > -20 && element.p72 !== 0) {
                                    validatep72 = true;
                                }
                            }
                            if (element.p73 !== null) {
                                if (element.p73 <= 50 && element.p73 > -20 && element.p73 !== 0) {
                                    validatep73 = true;
                                }
                            }
                            if (element.p74 !== null) {
                                if (element.p74 <= 50 && element.p74 > -20 && element.p74 !== 0) {
                                    validatep74 = true;
                                }
                            }
                        });

                        this.setState({
                            dataTemperatureFromApiP72: temperatures,
                            dataTemperatureFromApiP73: temperatures73,
                            dataTemperatureFromApiP74: temperatures74,
                            isShowChartsTemperature: isShowChartsTemperature,
                            valueState: this.state.verificationsOptions.temperature
                        });
                        if (response.length >= 1) {
                            if (validatep72 === true && validatep73 === true && validatep74 === true) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "3/3"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep72 === true && validatep73 === true) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "2/2"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep73 === true && validatep74 === true) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "2/2"

                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep74 === true && validatep72 === true) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "2/2"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep72 === true && validatep73 === false && validatep74 === false) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "1/1"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep72 === false && validatep73 === true && validatep74 === false) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "1/1"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            } else if (validatep72 === false && validatep73 === false && validatep74 === true) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "temperature": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    counterTemperatures: "1/1"
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            refreshing: false,
                                        }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);


                                });
                            }

                        } else {
                            this.setState({
                                responseEvents: response,
                                counterTemperatures: "0/0"
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        refreshing: false
                                    });
                                }, 2000);
                            });
                        }

                    } catch (error) {
                        return error;
                        // console.log(error);
                    }
                }
            } else {
                // this.goToPageErr();
                // return fetchResponse.status;
                alert("HTTP-Error: " + fetchResponse.status);
                return;
            }
        });

    }

    async getLocationCheck() {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };
        // this.setState({
        //     refreshing: false,
        // }, async () => {
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Location?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    response = await fetchResponse.json();
                    this.setState({
                        isLoading: false
                    });
                    if (response.length > 0) {
                        this.setState({
                            objMap: {
                                lat: response[0].latitud,
                                lng: response[0].longitud,
                                lastGPSPositionDate: response[0].lastGPSPositionDate,
                                option: "location"
                            }
                        }, () => {
                            if (!this.isEmpty(this.state.itemListToView)) {
                                return;
                            }
                            if (this.state.objMap.lat !== null) {
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "location": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response
                                }, () => {
                                    if (this.isEmpty(this.state.itemListToView)) {
                                        this.putActiveCheck(2, this.state.deviceCheckControlId);
                                    }
                                    // setTimeout(() => {

                                    //     // this.setState({
                                    //     //     refreshing: false,
                                    //     // }, () => {
                                    //     //     if (this.isEmpty(this.state.itemListToView)) {
                                    //     //         this.putActiveCheck(2, this.state.deviceCheckControlId);
                                    //     //     }
                                    //     // });
                                    // }, 2000);
                                });
                            } else {
                                this.setState({
                                    responseEvents: response,
                                    option: "location"
                                })
                                // , () => {
                                //     setTimeout(() => {
                                //         this.setState({
                                //             refreshing: false
                                //         });
                                //     }, 2000);
                                // })
                            }
                        });
                    }

                } catch (error) {
                    return error;
                    // console.log(error);
                }
            }
        } else {
            // this.goToPageErr();
            // return fetchResponse.status;
            alert("HTTP-Error: " + fetchResponse.status);
            return;
        }
        // });

    }

    async getCanBusCheck() {
        let response = "";
        let loading = true;
        this.setState({
            isLoading: true
        });

        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)
            // `[${newArrayBody.map(service => '[' + service + ']')}]`
        };

        try {
            this.setState({
                refreshing: false,
                option: "fms"
            }, async () => {
                try {
                    const url = `${Config.apiGateway.URL}CheckControl/CanBus?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`;
                    const fetchResponse = await fetch(url, settings);

                    if (fetchResponse.ok) {
                        response = await fetchResponse.json();

                        let objCanBus = {
                            speed: [],
                            pedal: [],
                            fuelUsed: [],
                            fuelLevelLiters: [],
                            fuelLevelPercentage: [],
                            rpm: [],
                            distance: []
                        };
                        let isShowCharts = {
                            speed: false,
                            pedal: false,
                            fuelUsed: false,
                            fuelLevelLiters: false,
                            fuelLevelPercentage: false,
                            rpm: false,
                            distance: false,
                        };

                        response.forEach((element, index) => {
                            objCanBus.speed.push({ x: index, y: element.speed });
                            objCanBus.pedal.push({ x: index, y: element.pedal });
                            objCanBus.fuelUsed.push({ x: index, y: element.fuelUsed });
                            objCanBus.fuelLevelLiters.push({ x: index, y: element.fuelLevelLiters });
                            objCanBus.fuelLevelPercentage.push({ x: index, y: element.fuelLevelPercentage });
                            objCanBus.rpm.push({ x: index, y: element.rpm });
                            objCanBus.distance.push({ x: index, y: element.distance });

                            if (element.speed > 0) isShowCharts.speed = true;
                            if (element.pedal > 0) isShowCharts.pedal = true;
                            if (element.fuelUsed > 0) isShowCharts.fuelUsed = true;
                            if (element.fuelLevelLiters > 0) isShowCharts.fuelLevelLiters = true;
                            if (element.fuelLevelPercentage > 0) isShowCharts.fuelLevelPercentage = true;
                            if (element.rpm > 0) isShowCharts.rpm = true;
                            if (element.distance > 0) isShowCharts.distance = true;
                        });

                        this.setState({
                            objCanBus: objCanBus,
                            isShowCharts: isShowCharts,
                            isLoading: false
                        });

                        let validate = false;
                        if (response.length >= 3) {
                            for (let i = 0; i < response.length; i++) {
                                const element = response[i];
                                if ((element.fuelLevelLiters > 0 && element.distance > 0) || (element.fuelLevelPercentage > 0 && element.distance > 0)) {
                                    validate = true;
                                    break;
                                } else {
                                    validate = false;

                                }
                            }

                            if (validate) {
                                const filteredData = response.filter(element =>
                                    (element.fuelLevelLiters > 0 && element.distance > 0) ||
                                    (element.fuelLevelPercentage > 0 && element.distance > 0)
                                );
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "fms": 2
                                    },
                                    valueState: 2,
                                    responseEvents: filteredData,
                                    validate: validate
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({ refreshing: false }, () => {
                                            if (this.isEmpty(this.state.itemListToView)) {
                                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                                            }
                                        });
                                    }, 2000);
                                });
                            } else {
                                this.setState({
                                    responseEvents: response,
                                    validate: false,
                                    refreshing: false,
                                    valueState: this.state.verificationsOptions.fms
                                });
                            }
                        } else {
                            this.setState({
                                responseEvents: response,
                                validate: false,
                                refreshing: false
                            });
                        }
                    } else {
                        //throw new Error(`HTTP error! status: ${fetchResponse.status}`);
                        this.setState({
                            message: `HTTP error! status: ${fetchResponse.status}`,
                            snackbarOpen: true,
                            itemListToView: {},
                            isLoading: false
                        });
                    }
                } catch (error) {
                    console.error("Fetch error:", error);
                    this.setState({
                        message: "Ha ocurrido un error interno.",
                        snackbarOpen: true,
                        itemListToView: {},
                        isLoading: false
                    });
                } finally {
                    loading = false;
                }
            });
        } catch (error) {
            console.error("Overall error:", error);
            this.setState({
                message: "Ha ocurrido un error interno.",
                snackbarOpen: true,
                itemListToView: {},
                isLoading: false
            });
        }
    }

    getLvcanCodec12Check = async () => {
        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Codec12?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
        if (fetchResponse.status === 502) {
            response = 'No Content';
            this.setState({
                isLoading: false,

            });
        } else
            if (fetchResponse.ok) {
                response = await fetchResponse.json();


                let listLvcan = [];
                let resultLvcanProgNum = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.codeCheck.includes("LVCAN ProgNum") : []);
                if (resultLvcanProgNum.length > 0) {
                    resultLvcanProgNum.forEach(element => {
                        listLvcan.push(element);

                        this.setState({
                            verificationsOptions: {
                                ...this.state.verificationsOptions,
                                "lvcan": 2
                            },
                            valueState: 2,
                            responseEvents: response,
                            listLvcan: listLvcan
                        }, () => {
                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                        });

                        // if (element.includes(this.state.valueTextFieldLvCan)) {


                        // } else {
                        //     listLvcan.push(element);
                        //     this.setState({
                        //         listLvcan: listLvcan
                        //     });
                        // }
                    });
                } else {
                    this.setState({

                        valueState: this.state.verificationsOptions.lvcan
                    });
                }
                this.setState({
                    isLoading: false
                });

                // LVCAN ProgNum: 11120
            }

    }

    contains(target, pattern) {
        var value = 0;
        pattern.forEach(function (word) {
            value = value + target.includes(word);
        });
        return (value === 1)
    }

    getTacografoCodec12Check = async () => {

        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Codec12?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
        let listTacografo = [];
        if (fetchResponse.status === 502) {
            response = 'No Content';
            this.setState({
                isLoading: false,

            });
        } else
            if (fetchResponse.status === 204) {
                response = 'No Content';
                this.setState({
                    isLoading: false,

                });
            } else if (fetchResponse.ok) {
                // Aquí puedes manejar otros estados exitosos
                response = await fetchResponse.json();
                this.setState({
                    isLoading: false,

                });
                //showMessageDownloadSend
                //showMessageTachoSend
                setTimeout(() => {
                    let downloadType = 0;
                    if (response[0] === 1 || response[0] === 2) {
                        downloadType = response[0].codeCheck;
                    }

                    let resultCan2 = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.codeCheck.includes("CAN_2") : []);

                    //let pattern = ["CAN_2:111", "K-Line:1"]

                    let resultkln = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.codeCheck.includes("KLN:") : []);
                    if (resultCan2.length > 0) {
                        for (let i = 0; i < resultCan2.length; i++) {
                            const element = resultCan2[i].codeCheck;

                            if (element.includes("CAN_2:111") || element.includes("K-Line:1")) {
                                listTacografo.push(element);
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "tacografo": 2,
                                        "tachoDownload": downloadType
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    listTacografo: listTacografo
                                }, () => {

                                    this.putActiveCheck(2, this.state.deviceCheckControlId);
                                });
                            } else {
                                listTacografo.push(element);
                                this.setState({
                                    listTacografo: listTacografo
                                });
                            }
                        }

                    }
                    if (resultkln.length > 0) {
                        for (let i = 0; i < resultkln.length; i++) {
                            const element = resultkln[i].codeCheck;

                            if (element.includes("KLN:111")) {
                                listTacografo.push(element);
                                this.setState({
                                    verificationsOptions: {
                                        ...this.state.verificationsOptions,
                                        "tacografo": 2
                                    },
                                    valueState: 2,
                                    responseEvents: response,
                                    listTacografo: listTacografo
                                }, () => {
                                    this.putActiveCheck(2, this.state.deviceCheckControlId);
                                });
                            } else {
                                listTacografo.push(element);
                                this.setState({
                                    listTacografo: listTacografo
                                });
                            }
                        }

                    }

                }, 1000);
            } else {
                // Manejo de errores para otros estados no exitosos
                console.error(`Error: ${fetchResponse.status} - ${fetchResponse.statusText}`);
                this.setState({
                    isLoading: false,

                });
            }

    }

    getTacografoCheck = async () => {

        this.setState({
            isLoading: true
        });
        let response = "";
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

        };
        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Tachograph?deviceCheckControlId=${this.isEmpty(this.state.responseCheckControl.deviceCheckControlId) ? this.state.responseCheckControl.deviceCheckControlId : this.state.deviceCheckControlId}`, settings);
        let listTacografo = [];
        if (fetchResponse.status === 204) {
            response = 'No Content';
            this.setState({
                isLoading: false
            });
        } else if (fetchResponse.ok) {
            // Aquí puedes manejar otros estados exitosos
            response = await fetchResponse.json();
            this.setState({
                isLoading: false
            });
            setTimeout(() => {
                let downloadType = 0;
                if (response[0].codeCheck === "1" || response[0].codeCheck === "2") {
                    downloadType = response[0].codeCheck;
                }

                let resultCan2 = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.codeCheck.includes("CAN_2") : []);

                //let pattern = ["CAN_2:111", "K-Line:1"]

                let resultkln = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.codeCheck.includes("KLN:") : []);
                if (resultCan2.length > 0) {
                    for (let i = 0; i < resultCan2.length; i++) {
                        const element = resultCan2[i];
                        if (element.codeCheck.includes("CAN_2:111") && element.codeCheck.includes("K-Line:1")) {
                            listTacografo.push(element);
                            this.setState({
                                verificationsOptions: {
                                    ...this.state.verificationsOptions,
                                    "tacografo": 2,
                                    "tachoDownload": downloadType
                                },
                                valueState: 2,
                                responseEvents: response,
                                listTacografo: listTacografo,
                                tachoDownload: downloadType
                            }, () => {

                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                            });
                        } else {
                            listTacografo.push(element);

                            this.setState({
                                listTacografo: listTacografo,
                                tachoDownload: downloadType,
                                valueState: this.state.verificationsOptions.tacografo
                            });

                        }
                    }

                } else {
                    this.setState({
                        valueState: this.state.verificationsOptions.tacografo
                    });
                }
                if (resultkln.length > 0) {
                    for (let i = 0; i < resultkln.length; i++) {
                        const element = resultkln[i];
                        if (element.includes("KLN:111")) {
                            listTacografo.push(element);
                            this.setState({
                                verificationsOptions: {
                                    ...this.state.verificationsOptions,
                                    "tacografo": 2,
                                    "tachoDownload": downloadType
                                },
                                valueState: 2,
                                responseEvents: response,
                                listTacografo: listTacografo,
                                tachoDownload: downloadType
                            }, () => {
                                this.putActiveCheck(2, this.state.deviceCheckControlId);
                            });
                        } else {
                            listTacografo.push(element);
                            this.setState({
                                listTacografo: listTacografo,
                                tachoDownload: downloadType,
                                valueState: this.state.verificationsOptions.tacografo
                            });
                        }
                    }

                } else {
                    this.setState({
                        valueState: this.state.verificationsOptions.tacografo
                    });
                }

            }, 500);
        } else {
            // Manejo de errores para otros estados no exitosos
            console.error(`Error: ${fetchResponse.status} - ${fetchResponse.statusText}`);
            this.setState({
                isLoading: false
            });
        }

    }

    refreshGetTacografoCodec12Check = (response) => {
        let listTacografo = [];
        let resultCan2 = response.filter(x => x.codeCheck !== "" || x.codeCheck !== undefined ? x.includes("CAN_2") : []);
        // let resultkLine = response.filter(x => x.includes("K-line:"));
        let resultkln = response.filter(x => x.includes("kln:"));
        if (resultCan2.length > 0) {
            for (let i = 0; i < resultCan2.length; i++) {
                if (resultCan2[i].codeCheck !== undefined || resultCan2[i].codeCheck !== "") {
                    const element = resultCan2[i].codeCheck;
                    if (element.includes("CAN_2:111") && element.includes("K-Line:1")) {
                        listTacografo.push(element);
                        this.setState({
                            verificationsOptions: {
                                ...this.state.verificationsOptions,
                                "tacografo": 2
                            },
                            valueState: 2,
                            responseEvents: response,
                            listTacografo: listTacografo
                        }, () => {

                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                        });
                    } else {
                        listTacografo.push(element);
                        this.setState({
                            listTacografo: listTacografo
                        });
                    }
                }

            }

        }
        if (resultkln.length > 0) {
            for (let i = 0; i < resultkln.length; i++) {
                const element = resultkln[i].codeCheck;
                if (element !== undefined || element !== "") {
                    if (element.includes("KLN:111")) {
                        listTacografo.push(element);
                        this.setState({
                            verificationsOptions: {
                                ...this.state.verificationsOptions,
                                "tacografo": 2
                            },
                            valueState: 2,
                            responseEvents: response,
                            listTacografo: listTacografo
                        }, () => {
                            this.putActiveCheck(2, this.state.deviceCheckControlId);
                        });
                    } else {
                        listTacografo.push(element);
                        this.setState({
                            listTacografo: listTacografo
                        });
                    }
                }

            }

        }
    }

    getTacografoCodec12Send = async (code) => {
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

        };

        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/Codec12Send?codec12Code=${code}&serialNumber=${this.state.serialNumber}&downloadType=${this.state.verificationsOptions.tachoDownload}`, settings);
        if (fetchResponse.ok) {
            await fetchResponse.json();
            this.showMessageTachoSend();
        }
    }

    getTacografoSend = async (code) => {
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            // body: JSON.stringify(objectToDelete)

        };

        const fetchResponse = await fetch(`${Config.apiGateway.URL}CheckControl/TachographSend?codec12Code=${code}&serialNumber=${this.state.serialNumber}&downloadType=${this.state.verificationsOptions.tachoDownload}`, settings);
        if (fetchResponse.ok) {

            await fetchResponse.json();
            this.showMessageDownloadSend();
            // this.getTacografoCheck();
            // this.setState({
            //     verificationsOptions: {
            //         ...this.state.verificationsOptions,
            //         "tacografo": 2
            //     },
            //     valueState: 2,
            // });
        }
    }

    onHandleOnclickBackButton = () => {
        this.setState({
            responseEvents: [],
            valueState: 0,
        });
    }

    handleOnClickBackButtonChequeo = () => {
        this.setState({
            responseEvents: [],
            valueState: 0,
            serialNumber: "0",
            verificationsOptions: {
                "contact": 0,
                "inputDigital": 0,
                "outputDigital": 0,
                "location": 0,
                "iButton": 0,
                "tacografo": 0,
                "temperature": 0,
                "lvcan": 0,
                "fms": 0,
                "tachoDownload": 0
            },
            verificationsOptionsView: {
                "contact": 0,
                "inputDigital": 0,
                "outputDigital": 0,
                "location": 0,
                "iButton": 0,
                "tacografo": 0,
                "temperature": 0,
                "lvcan": 0,
                "fms": 0,
                "tachoDownload": 0
            }
        });
    }

    getDataTopassConfigurationDevices = (deviceConfigurationSelected, action) => {
        if (action === "update") {
            this.setState({
                deviceConfigurationSelected: deviceConfigurationSelected,
                isOpenSnackbarSuccess: true,
                textSnackBarSuccess: "El registro se ha editado satisfactoriamente."
            });
        } else if (action === "add") {
            this.setState({
                deviceConfigurationSelected: deviceConfigurationSelected,
                isOpenSnackbarSuccess: true,
                textSnackBarSuccess: "El registro se ha creado satisfactoriamente."
            });
        } else if (action === "delete") {
            this.setState({
                deviceConfigurationSelected: deviceConfigurationSelected,
                isOpenSnackbarSuccess: true,
                textSnackBarSuccess: "El registro se ha eliminado satisfactoriamente."
            });
        } else if (action === "cancel") {
            this.setState({
                deviceConfigurationSelected: "",
                // isOpenSnackbarSuccess: true,
                // textSnackBarSuccess: "El registro se ha eliminado satisfactoriamente."
            });
        } else if (action === "edit") {
            this.setState({
                deviceConfigurationSelected: deviceConfigurationSelected,
                // isOpenSnackbarSuccess: true,
                // textSnackBarSuccess: "El registro se ha eliminado satisfactoriamente."
            });
        }
    }

    handleCloseSnackbarSuccess = () => {
        this.setState({
            isOpenSnackbarSuccess: false,
            textSnackBarSuccess: ""
        });
    }

    getDataTypeDevicesTopassConfiguration = (types, parametersDevicesConfiguration) => {
        this.setState({
            typesDevicesConfiguration: types,
            parametersDevicesConfiguration: parametersDevicesConfiguration
        });
    }

    contactView() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>

                    <List subheader={<ListSubheader>
                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                        {!this.isEmpty(this.state.itemListToView) ?
                            < ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contacto del vehículo" style={{ marginRight: "10px" }} />
                                {/* <ListItemText primary="Verificación" /> */}
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-contacto',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                            </ListItem>
                            :
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo',

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contacto del vehículo" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault(); // Evita el comportamiento predeterminado
                                            e.stopPropagation();
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-contacto',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                        }


                        {/* <p style={{ position: "absolute", top: "4px", right: "22px" }}> Verificación</p> */}

                    </ListSubheader>
                    } className={classes.root2}>
                    </List>
                    <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                        {
                            this.state.responseEvents.length < 1 ?
                                <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                    <Select
                                        style={{ textAlign: "left" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.contact}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleChangeComboBoxContact.bind(this)}
                                        disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                    >
                                        {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        {/* <MenuItem value={2}>Verificado</MenuItem> */}
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center" spacing={1}>
                                            <Grid item xs={6}>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <p style={{ margin: "0px", textAlign: "right" }}>({this.state.responseEvents.length > 1 ? 1 : this.state.responseEvents.length}/1) </p>
                                            </Grid>
                                            {this.state.responseEvents.map((value, index) => {
                                                return (
                                                    <Grid key={value.startDate + index} container justifyContent="center" spacing={1}>

                                                        <Grid item xs={3}>
                                                            <p style={{ marginTop: "9px" }}>Inicio: </p>

                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {this.formatDate(value.startDate)}
                                                            {/* <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p> */}
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <p style={{ marginTop: "9px" }}>Fin: </p>

                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {value.endDate !== null ? this.formatDate(value.endDate) : null}
                                                        </Grid>
                                                    </Grid>

                                                )

                                            })}
                                        </Grid>
                                    </Grid>

                                    {this.isEmpty(this.state.itemListToView) ?
                                        <div style={{ margin: "auto", width: "100%" }}>

                                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState.bind(this)}>Cambiar estado</Button>
                                        </div>
                                        : null}
                                </FormControl>


                                :
                                <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                    <Select
                                        style={{ textAlign: "left" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.valueState}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleChangeComboBoxContact.bind(this)}
                                    >
                                        <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem>
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        <MenuItem value={2}>Verificado</MenuItem>
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{ padding: "0px" }}>


                                        </Grid>
                                        <Grid container justifyContent="center" spacing={1}>
                                            <Grid item xs={6}>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <p style={{ margin: "0px", textAlign: "right" }}>({this.state.responseEvents.length >= 1 ? 1 : this.state.responseEvents.length}/1) </p>
                                            </Grid>

                                            {this.state.responseEvents.map((value, index) => {
                                                return (
                                                    <Grid style={{ paddingTop: "0px" }} key={value.startDate + index} container justifyContent="center" spacing={1}>
                                                        <Grid item xs={3}>
                                                            <p style={{ marginTop: "9px" }}>Inicio: </p>

                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {this.formatDate(value.startDate)}
                                                            {/* <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p> */}
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <p style={{ marginTop: "9px" }}>Fin: </p>

                                                        </Grid>
                                                        <Grid item xs={9}>

                                                            {value.endDate !== null ?
                                                                this.formatDate(value.endDate)
                                                                : "Sin finalizar"
                                                            }

                                                        </Grid>
                                                    </Grid>

                                                )

                                            })}
                                        </Grid>
                                    </Grid>

                                </FormControl>
                        }
                    </div>

                </div>

            </React.Fragment>

        )
    }

    InputDigitalView() {
        const { classes } = this.props;
        const { dataDigitalInput } = this.state;

        const areAllEventsEmpty = dataDigitalInput.din2.events.length === 0 &&
            dataDigitalInput.din3.events.length === 0 &&
            dataDigitalInput.din4.events.length === 0;
        return (
            <React.Fragment>
                <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                    <List subheader={
                        <ListSubheader>
                            {!this.isEmpty(this.state.itemListToView) ?
                                <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,
                                }}>
                                    <ListItemIcon>
                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Entradas digitales" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-entradas-digitales',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                </ListItem>
                                :
                                <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo',
                                }}>
                                    <ListItemIcon>
                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Entradas digitales" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-entradas-digitales',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                </ListItem>
                            }
                        </ListSubheader>
                    } className={classes.root2}>
                    </List>
                    <div style={{ width: "100%", margin: "auto", marginTop: "20px" }}>
                        {areAllEventsEmpty ? (
                            <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                <Select
                                    style={{ textAlign: "left", margin: 'auto', width: '300px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.inputDigital}
                                    placeholder="Selecciona un estado"
                                    onChange={this.handleChangeComboBoxInputDigital.bind(this)}
                                    disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                >
                                    <MenuItem value={1}>No aplica</MenuItem>
                                    <MenuItem value={0}>Pendiente</MenuItem>
                                </Select>
                                {this.isEmpty(this.state.itemListToView) &&
                                    <div style={{ margin: "auto", marginTop: 20 }}>
                                        <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState}>Cambiar estado</Button>
                                    </div>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din2"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN2 - ${dataDigitalInput.din2.name}`}
                                            items={dataDigitalInput.din2.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din3"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN3 - ${dataDigitalInput.din3.name}`}
                                            items={dataDigitalInput.din3.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din4"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN4 - ${dataDigitalInput.din4.name}`}
                                            items={dataDigitalInput.din4.events}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        ) : (
                            <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                <Select
                                    style={{ textAlign: "left", margin: 'auto', width: '300px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.valueState}
                                    placeholder="Selecciona un estado"
                                    onChange={this.handleChangeComboBoxInputDigital.bind(this)}
                                    disabled={
                                        !this.isEmpty(dataDigitalInput.din2.events) ||
                                        !this.isEmpty(dataDigitalInput.din3.events) ||
                                        !this.isEmpty(dataDigitalInput.din4.events)
                                    }
                                >
                                    <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem>
                                    <MenuItem value={1}>No aplica</MenuItem>
                                    <MenuItem value={2}>Verificado</MenuItem>
                                    <MenuItem value={0}>Pendiente</MenuItem>
                                </Select>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din2"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN2 - ${dataDigitalInput.din2.name}`}
                                            items={dataDigitalInput.din2.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din3"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN3 - ${dataDigitalInput.din3.name}`}
                                            items={dataDigitalInput.din3.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CardList
                                            sensorId="din4"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DIN4 - ${dataDigitalInput.din4.name}`}
                                            items={dataDigitalInput.din4.events}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    OutputDigitalView() {
        const { classes } = this.props;
        const { dataDigitalOutput } = this.state;

        let areAllEventsEmpty = dataDigitalOutput.douT1.events.length === 0 &&
            dataDigitalOutput.douT2.events.length === 0 &&
            dataDigitalOutput.douT3.events.length === 0 &&
            dataDigitalOutput.douT4.events.length === 0;

        if (dataDigitalOutput.douT1.events.length > 0 &&
            dataDigitalOutput.douT2.events.length > 0 &&
            dataDigitalOutput.douT3.events.length > 0 &&
            dataDigitalOutput.douT4.events.length > 0) {

            if (dataDigitalOutput.douT2.events[dataDigitalOutput.douT2.events.length - 1].endDate !== null) {
                areAllEventsEmpty = false;
            } else {
                areAllEventsEmpty = true;
            }
        }
        return (
            <React.Fragment>
                <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                    <List subheader={
                        <ListSubheader>
                            {!this.isEmpty(this.state.itemListToView) ?
                                <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,
                                }}>
                                    <ListItemIcon>
                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Salidas digitales" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-salidas-digitales',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                </ListItem>
                                :
                                <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo',
                                }}>
                                    <ListItemIcon>
                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Salidas digitales" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-salidas-digitales',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                </ListItem>
                            }
                        </ListSubheader>
                    } className={classes.root2}>
                    </List>
                    <div style={{ width: "100%", margin: "auto", marginTop: "20px" }}>
                        {areAllEventsEmpty ? (
                            <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                <Select
                                    style={{ textAlign: "left", margin: 'auto', width: '300px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.outputDigital}
                                    placeholder="Selecciona un estado"
                                    onChange={this.handleChangeComboBoxOutputDigital.bind(this)}
                                    disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                >
                                    <MenuItem value={1}>No aplica</MenuItem>
                                    <MenuItem value={0}>Pendiente</MenuItem>
                                </Select>
                                {this.isEmpty(this.state.itemListToView) &&
                                    <div style={{ margin: "auto", marginTop: 20 }}>
                                        <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState}>Cambiar estado</Button>
                                    </div>
                                }
                                <Grid container spacing={3}>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="1"
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            title={`DOUT1`}
                                            countdown={dataDigitalOutput.douT1.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT1.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT1.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT1.events}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="2"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            title={`DOUT2`}
                                            countdown={dataDigitalOutput.douT2.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT2.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT2.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT2.events}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="3"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            title={`DOUT3`}
                                            countdown={dataDigitalOutput.douT3.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT3.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT3.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT3.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="4"
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            title={`DOUT4`}
                                            countdown={dataDigitalOutput.douT4.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT4.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT4.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT4.events}
                                        />
                                    </Grid> */}
                                </Grid>
                            </FormControl>
                        ) : (
                            <FormControl style={{ margin: "0px" }} variant="outlined" className={classes.formControl}>
                                <Select
                                    style={{ textAlign: "left", margin: 'auto', width: '300px' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.valueState}
                                    placeholder="Selecciona un estado"
                                    onChange={this.handleChangeComboBoxOutputDigital.bind(this)}
                                    disabled={
                                        !this.isEmpty(dataDigitalOutput.douT1.events) ||
                                        !this.isEmpty(dataDigitalOutput.douT2.events) ||
                                        !this.isEmpty(dataDigitalOutput.douT3.events) ||
                                        !this.isEmpty(dataDigitalOutput.douT4.events)
                                    }
                                >
                                    <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem>
                                    <MenuItem value={1}>No aplica</MenuItem>
                                    <MenuItem value={2}>Verificado</MenuItem>
                                </Select>
                                <Grid container spacing={3}>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="1"
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            title={`DOUT1`}
                                            countdown={dataDigitalOutput.douT1.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT1.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT1.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT1.events}

                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="2"
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DOUT2`}
                                            countdown={dataDigitalOutput.douT2.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT2.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT2.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT2.events}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="3"
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DOUT3`}
                                            countdown={dataDigitalOutput.douT3.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT3.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT3.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT3.events}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <CardList
                                            sensorId="4"
                                            handleOnclickOutputDigitalUpdateSensors={this.handleOnclickOutputDigitalUpdateSensors}
                                            handleOnclickInputDigitalUpdateSensors={this.handleOnclickInputDigitalUpdateSensors} // Pasa la función como prop
                                            title={`DOUT4`}
                                            countdown={dataDigitalOutput.douT4.countdown}
                                            isButtonDisabled={dataDigitalOutput.douT4.isButtonDisabled}
                                            handleActivate={this.handleActivate}
                                            statusDout={dataDigitalOutput.douT4.status}
                                            getOutputDigitalCheck={this.getOutputDigitalCheck}
                                            items={dataDigitalOutput.douT4.events}
                                        />
                                    </Grid> */}
                                </Grid>
                            </FormControl>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    iButtonView() {
        const { classes } = this.props;
        return (
            <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                <Grid item xs={12} md={12}>
                    <List subheader={<ListSubheader>
                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                        {!this.isEmpty(this.state.itemListToView) ?
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="IButton" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-ibutton',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                            :
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo',

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="IButton" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-ibutton',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                        }


                        {/* <p style={{ position: "absolute", top: "4px", right: "22px" }}> Verificación</p> */}

                    </ListSubheader>
                    } className={classes.root2}>
                    </List>

                </Grid>
                <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                    {
                        this.state.responseEvents.length < 1 ?
                            <div>
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word" }} variant="outlined" className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ textAlign: "left" }}
                                        value={this.isEmpty(this.state.itemListToView) ? this.state.verificationsOptionsView.iButton !== 0 ? this.state.verificationsOptionsView.iButton : this.state.valueState : this.state.itemListToView[0].checkControl.iButton}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleOnChangeiButton.bind(this)}
                                        disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                    >
                                        {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        {/* <MenuItem value={2}>Verificado</MenuItem> */}
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word" }} variant="outlined" className={classes.formControl}>
                                    <Select

                                        style={{ textAlign: "left" }}
                                        value={this.state.iButtonConfiguration}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleOnChangeiButtonConfiguration.bind(this)}
                                    //disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                    >
                                        <MenuItem value={0}>Desconectado</MenuItem>
                                        <MenuItem value={1}>Autorizado</MenuItem>
                                        <MenuItem value={2}>Lectura</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid item xs={12}>

                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <p style={{ margin: "0px", textAlign: "right" }}>({this.state.responseEvents.length >= 1 ? 1 : this.state.responseEvents.length}/1) </p>
                                        </Grid>
                                        {this.state.responseEvents.map((value) => {
                                            return (
                                                <Grid key={value.starDate + "iButton"} container justifyContent="center" spacing={1}>
                                                    <Grid item xs={3}>
                                                        <p style={{ marginTop: "9px" }}>Inicio: </p>

                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {this.formatDate(value.startDate)}
                                                        {/* <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p> */}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p style={{ marginTop: "9px" }}>Valor: </p>

                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {value.p78}

                                                    </Grid>
                                                </Grid>

                                            )

                                        })}
                                    </Grid>
                                </Grid>

                                {this.isEmpty(this.state.itemListToView) ?
                                    <div style={{ margin: "auto", width: "100%" }}>

                                        <Button style={{ width: "220px", borderRadius: "20px", fontSize: "1.3rem", marginBottom: "10px" }} variant="outlined" onClick={this.IButtonSendParameter.bind(this)}>Enviar configuración</Button>

                                        <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState.bind(this)}>Cambiar estado</Button>
                                    </div>
                                    : null}

                            </div>
                            :
                            <div>
                                <FormControl style={{ marginTop: "0px", width: "100%" }} variant="outlined" className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ textAlign: "left" }}
                                        value={this.state.valueState}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleOnChangeiButton.bind(this)}
                                        disabled={this.state.responseEvents.length >= 1 ? true : false}
                                    >
                                        {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        <MenuItem value={2}>Verificado</MenuItem>
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word" }} variant="outlined" className={classes.formControl}>
                                    <Select

                                        style={{ textAlign: "left" }}
                                        value={this.state.iButtonConfiguration}
                                        placeholder="Selecciona un estado"
                                        onChange={this.handleOnChangeiButtonConfiguration.bind(this)}
                                    //disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                    >
                                        <MenuItem value={0}>Desconectado</MenuItem>
                                        <MenuItem value={1}>Autorizado</MenuItem>
                                        <MenuItem value={2}>Lectura</MenuItem>
                                    </Select>
                                </FormControl>

                                <Grid item xs={12}>

                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <p style={{ margin: "0px", textAlign: "right" }}>({this.state.responseEvents.length >= 1 ? 1 : this.state.responseEvents.length}/1) </p>
                                        </Grid>
                                        <div style={{ margin: "auto", width: "100%" }}>
                                            <Button style={{ width: "220px", borderRadius: "20px", fontSize: "1.3rem", marginBottom: "10px" }} variant="outlined" onClick={this.IButtonSendParameter.bind(this)}>Enviar configuración</Button>
                                        </div>
                                        {this.state.responseEvents.map((value) => {
                                            return (
                                                <Grid key={value.starDate + "iButton"} container justifyContent="center" spacing={1}>
                                                    <Grid item xs={3}>
                                                        <p style={{ marginTop: "9px" }}>Inicio: </p>

                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        {this.formatDate(value.startDate)}
                                                        {/* <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p> */}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p style={{ marginTop: "9px" }}>Valor: </p>

                                                    </Grid>
                                                    <Grid item xs={9} style={{ wordWrap: "break-word" }}>

                                                        {value.p78}

                                                    </Grid>
                                                </Grid>

                                            )

                                        })}
                                    </Grid>
                                </Grid>

                            </div>
                    }
                </div>
            </div>
        )
    }

    temperatureView() {
        const { classes } = this.props;
        let dataTemperatureP72 = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Temperatura',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dataTemperatureFromApiP72

                }
            ]
        }
        let dataTemperatureP73 = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Temperatura',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dataTemperatureFromApiP73

                }
            ]
        }
        let dataTemperatureP74 = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Temperatura',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.dataTemperatureFromApiP74

                }
            ]
        }
        return (
            <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                <Grid item xs={12} md={12}>
                    <List subheader={<ListSubheader>
                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                        {!this.isEmpty(this.state.itemListToView) ?
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Temperatura" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-temperatura',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                            :
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo',

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Temperatura" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-temperatura',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                        }

                    </ListSubheader>
                    } className={classes.root2}>
                    </List>

                </Grid>
                <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                    {this.state.responseEvents.length < 1 ?
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ textAlign: "left" }}
                                //value={this.state.valueState}
                                placeholder="Selecciona un estado"
                                value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.temperature}
                                onChange={this.handleOnChangeTemperature.bind(this)}
                                disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                {/* {<MenuItem value={2}>Verificado</MenuItem>} */}
                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.valueState}
                                placeholder="Selecciona un estado"
                                style={{ textAlign: "left" }}
                                onChange={this.handleOnChangeTemperature.bind(this)}
                                disabled={this.state.responseEvents.length >= 3 ? true : false}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                <MenuItem value={2}>Verificado</MenuItem>
                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>

                    }
                    <div>

                        <TextField
                            // style={{ width: "100%", height: "49px" }}
                            className={classes.formControl}
                            placeholder="Sonda"
                            variant="outlined"
                            size="medium"

                            //value={this.state.email}
                            //disabled={this.props.selectedObj.length > 0 ? true : false}
                            onChange={this.handleOnChangeSondaTemperatura.bind(this)}
                            error={this.state.errorSonda}
                            id="outlined-error"
                            helperText={this.state.errorSondaText}
                            required
                            margin="none"
                            inputProps={{ maxLength: 12 }}
                        />
                    </div>
                    {this.state.responseEvents.length < 3 ?
                        <div style={{ margin: "auto", width: "100%" }}>
                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem", marginBottom: "5px" }} disabled={this.state.boolDisabledButtonSonda} onClick={this.saveDataSondaTemperature.bind(this, 2)} variant="outlined">Actualizar sonda</Button>
                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem", marginTop: "5px" }} variant="outlined" onClick={this.verifyState.bind(this)}>Cambiar estado</Button>
                        </div>
                        : null
                    }
                    <div>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6}>
                                <p style={{ margin: "0px", textAlign: "right", marginRight: "25px" }}>({this.state.counterTemperatures}) </p>
                                {/* <p style={{ margin: "0px", textAlign: "right", marginRight: "25px" }}>({this.state.responseEvents.length >= 3 ? 3 : this.state.responseEvents.length}/3) </p> */}
                            </Grid>
                        </Grid>

                        {this.state.isShowChartsTemperature.p72 ?
                            <Line ref="chart" data={dataTemperatureP72}
                                options={optionsLayout}

                            />
                            :
                            null
                        }

                    </div>

                </div>

                {this.state.isShowChartsTemperature.p73 ?
                    <div style={{ marginTop: "30px" }}>
                        <Line ref="chart" data={dataTemperatureP73}
                            options={optionsLayout}

                        />
                    </div>
                    : null
                }

                {this.state.isShowChartsTemperature.p74 ?
                    <div style={{ marginTop: "30px" }}>
                        <Line ref="chart" data={dataTemperatureP74}
                            options={optionsLayout}

                        />
                    </div>
                    : null
                }

                {/* <Link to="/instaladores/" transition='glide-right'>
                            Back to home page
                        </Link> */}
                {/* <FormCheckControl /> */}
            </div>
        )
    }

    canbusView() {
        const { classes } = this.props;
        let dataCanBusSpeed = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Velocidad',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.speed

                }
            ]
        }
        let dataCanBusPedal = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Pedal',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.pedal

                }
            ]
        }
        let dataCanBusFuelUsed = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Combustible utilizado',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.fuelUsed

                }
            ]
        }
        let dataCanBusFuelLevelLiters = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Nivel de combustible litros',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.fuelLevelLiters

                }
            ]
        }
        let dataCanBusFuelLevelPercentage = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Nivel de combustible porcentaje',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.fuelLevelPercentage

                }
            ]
        }
        let dataCanBusRpm = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Revoluciones por minuto',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.rpm

                }
            ]
        }
        let dataCanBusDistance = {
            labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            datasets: [
                {
                    label: 'Distancia',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',

                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.objCanBus.distance

                }
            ]
        }
        return (
            <React.Fragment>
                <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                    <List style={{ paddingLeft: "0px", paddingRight: "0px" }} subheader={<ListSubheader>
                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                        {!this.isEmpty(this.state.itemListToView) ?
                            < ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,

                            }}>
                                <ListItemIcon>
                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Can-Bus" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-localizaci%C3%B3n',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                            :
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo',

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Can-Bus" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-localizaci%C3%B3n',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                        }

                    </ListSubheader>
                    } className={classes.root2}>
                    </List>

                </div>

                <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                    {!this.state.validate ?
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                //value={this.state.valueState}
                                placeholder="Selecciona un estado"
                                value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.fms}
                                onChange={this.handleOnChangeFms.bind(this)}
                                disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                                style={{ textAlign: "left" }}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                <MenuItem value={3}>Verificado con errores</MenuItem>
                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.isEmpty(this.state.itemListToView) ? this.state.verificationsOptions.fms : this.state.itemListToView[0].checkControl.fms}
                                placeholder="Selecciona un estado"
                                onChange={this.handleOnChangeFms.bind(this)}
                                disabled={this.state.validate ? true : false}
                                style={{ textAlign: "left" }}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                <MenuItem value={2}>Verificado</MenuItem>

                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    }


                    {!this.state.validate ?
                        <div style={{ margin: "auto", width: "100%" }}>

                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState.bind(this)}>Cambiar estado</Button>
                        </div>
                        : null
                    }
                </div>
                <div style={{ marginTop: "30px" }}>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={6}>

                        </Grid>
                        <Grid item xs={6}>
                            <p style={{ margin: "0px", textAlign: "right", marginRight: "25px" }}>({this.state.responseEvents.length >= 3 ? 3 : this.state.responseEvents.length}/3) </p>
                        </Grid>
                    </Grid>

                    {this.state.isShowCharts.distance ?
                        <div >
                            <Line ref="chart" data={dataCanBusDistance}
                                options={optionsLayout}
                            />
                        </div>
                        : null
                    }

                    {this.state.isShowCharts.fuelLevelLiters ?
                        <div style={{ marginTop: "30px" }}>
                            <Line ref="chart" data={dataCanBusFuelLevelLiters}
                                options={optionsLayout}

                            />
                        </div>
                        : null
                    }

                    {this.state.isShowCharts.fuelLevelPercentage ?
                        <div style={{ marginTop: "30px" }}>
                            <Line ref="chart" data={dataCanBusFuelLevelPercentage}
                                options={optionsLayout}

                            />
                        </div>
                        : null
                    }

                    {this.state.isShowCharts.speed ?
                        <div style={{ marginTop: "30px" }}>
                            <Line ref="chart" data={dataCanBusSpeed}
                                options={optionsLayout}
                            />
                        </div>

                        : null
                    }


                </div>
                {this.state.isShowCharts.pedal ?
                    <div style={{ marginTop: "30px" }}>
                        <Line ref="chart" data={dataCanBusPedal}
                            options={optionsLayout}
                        />
                    </div>
                    : null
                }


                {this.state.isShowCharts.fuelUsed ?
                    <div style={{ marginTop: "30px" }}>
                        <Line ref="chart" data={dataCanBusFuelUsed}
                            options={optionsLayout}

                        />
                    </div>
                    : null
                }


                {this.state.isShowCharts.rpm ?
                    <div style={{ marginTop: "30px" }}>
                        <Line ref="chart" data={dataCanBusRpm}
                            options={optionsLayout}

                        />
                    </div>
                    : null
                }





                {/* <Link to="/instaladores/" transition='glide-right'>
                            Back to home page
                        </Link> */}
                {/* <FormCheckControl /> */}
            </React.Fragment>

        )
    }

    localizationView() {
        const { classes } = this.props;
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={12}
                defaultCenter={{ lat: this.state.objMap.lat, lng: this.state.objMap.lng }}

            >
                {/* {props.isMarkerShown &&  */}
                <Marker position={{ lat: this.state.objMap.lat, lng: this.state.objMap.lng }} />
            </GoogleMap>
        ));
        return (
            <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                <Grid item xs={12} md={12}>
                    <List subheader={<ListSubheader>
                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                        {!this.isEmpty(this.state.itemListToView) ?
                            < ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo/' + this.state.itemListToView[0].serialNumber,

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Localización" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-localizaci%C3%B3n',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                            :
                            <ListItem onClick={this.onHandleOnclickBackButton.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                pathname: '/dashboard/instaladores/checkeo',

                            }}>
                                <ListItemIcon>

                                    <ArrowBackIosRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Localización" style={{ marginRight: "10px" }} />
                                <IconButton edge="end" aria-label="delete">
                                    <HelpOutlineIcon
                                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                            window.open(
                                                'https://ayudawiki.4gflota.com/es/instalaciones#verificaci%C3%B3n-de-localizaci%C3%B3n',
                                                '_blank'
                                            ); // Reemplaza con tu enlace
                                        }}
                                    />
                                </IconButton>
                                {/* <ListItemText primary="Verificación" /> */}
                            </ListItem>
                        }

                    </ListSubheader>
                    } className={classes.root2}>
                    </List>

                </Grid>
                <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                    {this.state.responseEvents.length < 1 ?
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                style={{ textAlign: "left" }}
                                id="demo-simple-select"
                                //value={this.state.valueState}
                                placeholder="Selecciona un estado"
                                value={this.isEmpty(this.state.itemListToView) ? this.state.valueState : this.state.itemListToView[0].checkControl.location}
                                onChange={this.handleOnChangeLocation.bind(this)}
                                disabled={!this.isEmpty(this.state.itemListToView) ? true : false}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                <MenuItem value={2}>Verificado</MenuItem>
                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.isEmpty(this.state.itemListToView) ? this.state.verificationsOptions.location : this.state.itemListToView[0].checkControl.location}
                                placeholder="Selecciona un estado"
                                onChange={this.handleOnChangeLocation.bind(this)}
                                disabled={this.state.responseEvents.length >= 1 ? true : false}
                                style={{ textAlign: "left" }}
                            >
                                {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                <MenuItem value={1}>No aplica</MenuItem>
                                <MenuItem value={2}>Verificado</MenuItem>
                                <MenuItem value={0}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    {this.isEmpty(this.props.itemListToView) ?
                        <div style={{ margin: "auto", width: "100%" }}>

                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifyState.bind(this)}>Cambiar estado</Button>
                        </div>
                        : null
                    }
                </div>
                <div style={{ marginTop: "30px" }}>
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={9}>
                            <p>Última posición GPS: {this.state.responseEvents.length >= 1 || !this.isEmpty(this.state.itemListToView) ? this.formatDate(this.state.objMap.lastGPSPositionDate) : ""}</p>
                        </Grid>
                        <Grid item xs={3}>
                            <p style={{ margin: "0px", textAlign: "right", marginRight: "25px" }}>({this.state.responseEvents.length >= 1 || !this.isEmpty(this.state.itemListToView) ? 1 : this.state.responseEvents.length}/1) </p>
                        </Grid>
                    </Grid>
                    <div style={{ margin: "auto", marginBottom: "60px" }}>
                        <MyMapComponent
                            isMarkerShown={false}
                            //googleMapsClientId={'gme-wirelesslogic'}
                            key={"AIzaSyDXhVYV0tKNy9kgA6mdHKX2F2H8LlbGnhk"}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDXhVYV0tKNy9kgA6mdHKX2F2H8LlbGnhk&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%`, width: "90%", margin: "auto", marginTop: "20px" }} />}
                        />

                    </div>
                </div>
                {/* <Link to="/instaladores/" transition='glide-right'>
                            Back to home page
                        </Link> */}
                {/* <FormCheckControl /> */}
            </div>
        )
    }

    handleOnChangeDeviceNumberPlate(e) {
        this.setState({
            deviceNumberPlate: e.target.value
        });
    }

    setValueTextFieldLvCan = (valueTextField) => {
        this.setState({
            valueTextFieldLvCan: valueTextField
        });
    }

    getActivityCRM = async () => {
        try {
            //const token = 'TU_TOKEN_JWT'; // Reemplaza con tu token JWT
            const response = await fetch(`${Config.apiGateway.URL}Installers/Activity?email=${userDataInfo.email}&subject=${this.state.serialNumber}`, {
                method: 'GET',
                // headers: {
                //   'Authorization': `Bearer ${token}`
                // }
            });

            if (response.ok) {
                const jsonData = await response.json();

                if (jsonData.length > 0) {
                    localStorage.setItem("activityIdCRM", jsonData[0].activityid);
                    this.changeStatusActivityCRM();
                }
            } else {
                console.error('Error en la respuesta:', response.status);
            }
        } catch (error) {
            console.error('Error en la llamada:', error);
        }
    }

    handleOnclickInputDigitalUpdateSensors = (sensor) => {
        console.log(`Click en sensor ${sensor}`);
        // Aquí va la lógica para manejar el clic en el sensor específico
    };

    handleActivate = (sensorId) => {
        this.handleOnclickOutputDigitalUpdateSensors(sensorId);
    }

    handleOnclickNavigation = (sensor) => {

        console.log(sensor);
    }

    handleGoBack = () => {
        this.props.history.goBack();
    };

    activateDevice = async () => {
        const settings = {
            method: 'POST',
            headers: {
                //'accessToken': this.props.location.state.AccessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(objectToSave)
        };

        const fetchResponse = await fetch(`${Config.apiGateway.URL}Devices/Activate?serialNumber=${this.state.serialNumber}`, settings);
        if (fetchResponse.ok) {
            if (fetchResponse !== null) {
                try {
                    //await fetchResponse.json();
                    this.setState({
                        message: "Activación realizada correctamente.",
                        snackbarOpen: true
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            // La respuesta no es exitosa
            if (fetchResponse.status === 400) {
                // Manejar el bad request aquí
                // this.setState({
                //     message: await fetchResponse.json(),
                //     snackbarOpen: true
                // });
            } else {
                // Manejar otros códigos de estado aquí
                console.log('Otro error:', fetchResponse.status);
            }
        }
    }

    render() {
        const { classes } = this.props;
        const options = this.state.verificationsOptions;
        return (
            <div style={{ height: "100%" }}>
                <Router>
                    <Navigation>
                        {this.state.redirect === true ?
                            <Redirect to="/dashboard/instaladores/checkeo" />
                            : ""}
                        {this.state.redirectToVerificationPage === true ?
                            <Redirect to="/dashboard/instaladores/" />
                            : ""}
                        <Route exact path="/dashboard/instaladores/" >
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List style={{ overflowY: "scroll" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <div>

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </div>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Verificación</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>

                            </Grid>
                            <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                                <TextField
                                    id="outlined-number"
                                    // label="Número de serie"
                                    value={this.state.serialNumber}
                                    placeholder="Número de serie"
                                    type="number"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    variant="outlined"
                                    onChange={this.handleOnChange.bind(this)}
                                    inputProps={{ inputMode: 'numeric' }}
                                />
                                <div>
                                    <IconButton onClick={this.toggleScanner} aria-label="camera">
                                        <CameraIcon /> {/* Icono de cámara */}
                                    </IconButton>

                                    {this.state.scannerActive && <BarcodeScanner2 setSerialNumberFromScan={this.setSerialNumberFromScan.bind(this)} />}
                                </div>
                                <div style={{ margin: "auto", width: "180px" }}>
                                    {/* <Link to="/dashboard/instaladores/checkeo" transition='glide-left'>
                                        
                                    </Link> */}
                                    <Button style={{ width: "180px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined" onClick={this.verifySerialNumber.bind(this)}>Verificar</Button>
                                    {/* <Link
                                    component="button"
                                    style={{border:"1px solid rgba(0, 0, 0, 0.23)", font:"Roboto", height: "27px", width: "130px", borderRadius:"15px", background: "#fff", fontSize: "12px", marginTop:"40px", letterSpacing: "0.02857em"}}
                                    variant="body2"
                                    onClick={this.verifySerialNumber.bind(this)}
                                >
                                    Verificar
                                </Link> */}
                                </div>
                            </div>

                            <Dialog
                                open={this.state.isOpenDialog}
                                onClose={this.handleCloseDialog.bind(this)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Alerta de inicio chequeo"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        ¿ Desea iniciar un chequeo?
                                    </DialogContentText>
                                    <TextField
                                        className={classes.textFieldDialog}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Matrícula"
                                        type="text"
                                        fullWidth
                                        onChange={this.handleOnChangeDeviceNumberPlate.bind(this)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseDialog.bind(this)} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={this.redirect.bind(this)} color="primary">
                                        Aceptar
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Route>

                        <Route exact path="/dashboard/instaladores/checkeo">
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List subheader={<ListSubheader>
                                    {this.props.match.path !== "/dashboard/historico" ?
                                        <div>
                                            <ListItem onClick={this.handleOnClickBackButtonChequeo.bind(this)} style={{ paddingLeft: "0px", paddingRight: "0px" }} button component={Link} transition='glide-left' to={{
                                                pathname: '/dashboard/instaladores/',
                                                // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                                // companyName: this.state.companyName
                                            }}>
                                                <ListItemIcon>

                                                    <ArrowBackIosRoundedIcon />
                                                </ListItemIcon>

                                            </ListItem>
                                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                                            <p style={{ position: "absolute", top: "4px", left: "50px" }}> CHECK CONTROL</p>
                                        </div>
                                        :
                                        <div>
                                            <ListItem style={{ paddingLeft: "0px", paddingRight: "0px" }} button component={Link} transition='glide-right' to={{
                                                pathname: '/dashboard/historico/',
                                                // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                                // companyName: this.state.companyName
                                            }}>
                                                <ListItemIcon>

                                                    <ArrowBackIosRoundedIcon />
                                                </ListItemIcon>
                                            </ListItem>
                                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                                            <p style={{ position: "absolute", top: "4px", right: "22px" }}> CHECK CONTROL HISTORICO</p>
                                        </div>
                                    }



                                </ListSubheader>
                                } className={classes.root}>

                                    <Divider style={{ marginTop: "10px", marginBottom: "8px" }} />
                                    <p style={{ margin: "0px", textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}>{this.state.responseCheckControl.serialNumber}({this.state.responseCheckControl.deviceAlias}) - {this.state.responseCheckControl.deviceNumberPlate}</p>

                                    <Button onClick={this.activateDevice.bind(this)} variant='outlined' style={{ width: "100%", borderRadius: "20px", fontSize: "1.3rem", marginBottom: "10px", marginTop: "10px" }}>Activar transmisión</Button>

                                    <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />

                                    <ListItem onClick={this.getIgnitionKeyCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/contact',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>

                                        <ListItemIcon>
                                            {this.state.verificationsOptions.contact === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.contact === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }

                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Contacto puesto / quitado" />
                                        {/* <ListItem button component={Link} to={{
pathname: '/instaladores/a',
// state: this.props.location.state !== undefined ? this.props.location.state : "",
// companyName: this.state.companyName
}}
// to="/dashboard/mapa"
// onClick={onItemClick('Page 3')}
>

</ListItem> */}

                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getInputDigitalCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/inputDigital',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>

                                        <ListItemIcon>
                                            {this.state.verificationsOptions.inputDigital === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.inputDigital === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }

                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Entradas digitales" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getOutputDigitalCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/outputDigital',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>

                                        <ListItemIcon>
                                            {this.state.verificationsOptions.outputDigital === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.outputDigital === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }

                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Salidas digitales" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getIButtonCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/iButton',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.iButton === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.iButton === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="IButton" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getTemperatureCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/temperatura',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.temperature === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.temperature === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Temperatura" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getLocationCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/localizacion',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.location === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.location === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Localización" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getCanBusCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/can-bus',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.fms === 1 ? (
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                            ) : this.state.verificationsOptions.fms === 0 ? (
                                                <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                            ) : this.state.verificationsOptions.fms === 3 ? (
                                                <ErrorIcon style={{ color: "#32a852" }} />
                                            ) : this.state.verificationsOptions.fms === 2 ? (
                                                <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            ) : (
                                                <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Can-Bus" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />


                                    <ListItem
                                        onClick={this.getTacografoCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                            pathname: '/instaladores/checkeo/tacografo',

                                        }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.tacografo === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.tacografo === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Tacógrafo" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

                                    <ListItem
                                        onClick={this.getLvcanCodec12Check.bind(this)}
                                        button component={Link} transition='glide-left' to={{
                                            pathname: '/instaladores/checkeo/lvCan',

                                        }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.lvcan === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.lvcan === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="LvCan" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />




                                    <Dialog
                                        open={this.state.isOpenDialogFinalizeCheckControl}
                                        onClose={this.handleCloseDialog.bind(this)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{"Alerta de fin chequeo"}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                ¿ Seguro/a que quiere finalizar el chequeo ?
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleCloseDialogFinalizeCheckControl.bind(this)} color="primary">
                                                Cancelar
                                            </Button>
                                            <Button onClick={this.handleAcceptDialogFinalizeCheckControl.bind(this)} color="primary">
                                                Aceptar
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <div style={{ margin: "auto", width: "300px" }}>
                                        <p style={{ marginTop: "30px", textAlign: "center" }}>Inicio: {this.state.responseCheckControl.startCheckControlDateUtc !== null ? this.formatDate(this.state.responseCheckControl.startCheckControlDateUtc) : this.state.dateFirstTime}</p>

                                        {options.contact !== 0 && options.iButton !== 0 && options.temperature
                                            &&
                                            options.location !== 0 && options.iButton !== 0 && options.tacografo !== 0 && options.temperature !== 0 &&
                                            options.lvcan !== 0 && options.fms !== 0 && options.inputDigital !== 0 && options.outputDigital !== 0
                                            ?
                                            <div style={{ margin: "auto", width: "180px", marginTop: "10px" }}>
                                                <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} onClick={this.onClickFinalizeCheckControl.bind(this)} variant="outlined">Finalizar chequeo</Button>
                                            </div>
                                            :
                                            null}
                                    </div>



                                </List>

                            </Grid>

                        </Route>

                        <Route exact path="/dashboard/historico/listado">
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List subheader={<ListSubheader>
                                    {this.props.match.path !== "/dashboard/historico" ?
                                        <div>
                                            <ListItem style={{ paddingLeft: "0px", paddingRight: "0px" }} button component={Link} transition='glide-left' to={{
                                                pathname: '/dashboard/instaladores/',
                                                // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                                // companyName: this.state.companyName
                                            }}>
                                                <ListItemIcon>

                                                    <ArrowBackIosRoundedIcon />
                                                </ListItemIcon>

                                            </ListItem>
                                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                                            <p style={{ position: "absolute", top: "4px", left: "50px" }}> CHECK CONTROL</p>
                                        </div>
                                        :
                                        <div>
                                            <ListItem style={{ paddingLeft: "0px", paddingRight: "0px" }} button component={Link} transition='glide-left' to={{
                                                pathname: '/dashboard/historico/',
                                                // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                                // companyName: this.state.companyName
                                            }}>
                                                <ListItemIcon>

                                                    <ArrowBackIosRoundedIcon />
                                                </ListItemIcon>

                                            </ListItem>
                                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                                            <p style={{ position: "absolute", top: "4px", right: "22px" }}> CHECK CONTROL HISTORICO</p>
                                        </div>
                                    }



                                </ListSubheader>
                                } className={classes.root}>
                                    <Divider style={{ marginTop: "30px", marginBottom: "8px" }} />

                                    {
                                        this.state.listDataHistory.map((value) => {
                                            const labelId = `${value.deviceCheckControlId}`;
                                            return (
                                                <ListItem className={classes.listItem} onClick={this.getDataSelectedList.bind(this, value.requestCheckControlDateUtc)} key={value.deviceCheckControlId} button component={Link} transition='glide-left' to={{
                                                    pathname: '/dashboard/instaladores/checkeo/' + value.serialNumber,
                                                    // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                                    // companyName: this.state.companyName
                                                }}>
                                                    {/* <ListItemIcon>
                                                    {this.state.verificationsOptions.contact === 1 ?
                                                        <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                        :
                                                        this.state.verificationsOptions.contact === 0 ?
                                                            <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                            :
                                                            <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                                    }

                                                </ListItemIcon> */}
                                                    <ListItemText id={labelId} primary={value.serialNumber + " - " + value.deviceAlias} secondary={
                                                        this.state.valueComboHistorical === 1 ?
                                                            this.formatDate(value.requestCheckControlDateUtc)
                                                            :
                                                            this.state.valueComboHistorical === 2 ?
                                                                this.formatDate(value.startCheckControlDateUtc)
                                                                :
                                                                this.formatDate(value.endCheckControlDateUtc)
                                                    } />
                                                    {/* <ListItem button component={Link} to={{
                                            pathname: '/instaladores/a',
                                            // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                            // companyName: this.state.companyName
                                        }}
                                        // to="/dashboard/mapa"
                                        // onClick={onItemClick('Page 3')}
                                        >
        
                                        </ListItem> */}

                                                    <ArrowForwardIosOutlinedIcon color="action" />
                                                </ListItem>

                                            )
                                        })}




                                    {/* <div style={{ margin: "auto", width: "300px" }}>
                                        <p style={{ marginTop: "30px", textAlign: "center" }}>Inicio: 17 de febrero 2021 12:32:54</p>
                                        <p style={{ textAlign: "center" }}></p>
                                        {options.contact !== 0
                                            // && 
                                            // options.location !== 0 && options.iButton !== 0 && options.tacografo !== 0 && options.temperature !== 0 &&
                                            //     options.lvcan !== 0 && options.fms !== 0 
                                            ?
                                            <div style={{ margin: "auto", width: "180px" }}>
                                                <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} onClick={this.onClickFinalizeCheckControl.bind(this)} variant="outlined">Finalizar Checkeo</Button>
                                            </div>
                                            :
                                            null}
                                    </div> */}
                                </List>
                            </Grid>

                        </Route>

                        <Route exact path="/dashboard/instaladores/checkeo/:id">
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List subheader={<ListSubheader>

                                    <div>
                                        <ListItem style={{ paddingLeft: "0px", paddingRight: "0px" }} button component={Link} transition='glide-left' to={{
                                            pathname: '/dashboard/historico/listado',
                                            // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                            // companyName: this.state.companyName
                                        }}>
                                            <ListItemIcon>

                                                <ArrowBackIosRoundedIcon />
                                            </ListItemIcon>

                                        </ListItem>
                                        {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                                        <p style={{ position: "absolute", top: "4px", right: "22px" }}> CHECK CONTROL HISTORICO</p>
                                    </div>
                                </ListSubheader>
                                } className={classes.root}>

                                    <Divider style={{ marginTop: "10px", marginBottom: "8px" }} />
                                    <p style={{ margin: "0px", textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}>{this.state.itemListToView[0] !== undefined ? this.state.itemListToView[0].serialNumber + "(" + this.state.itemListToView[0].deviceAlias + ")" : ""} - {this.state.itemListToView[0] !== undefined ? this.state.itemListToView[0].deviceNumberPlate : ""}</p>
                                    {
                                        this.state.itemListToView[0] && this.state.itemListToView[0].closedByUser === false &&
                                        <p style={{ textAlign: "center", color: "#eb4034" }}>
                                            El chequeo se cerró por antigüedad mayor a 3 meses.
                                        </p>
                                    }

                                    <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
                                    <ListItem onClick={this.getIgnitionKeyCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/contact',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>
                                        <ListItemIcon>
                                            {this.state.itemListToView[0] !== undefined ? this.state.itemListToView[0].checkControl.contact === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.itemListToView[0].checkControl.contact === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                                : null
                                            }

                                        </ListItemIcon>
                                        <ListItemText primary={"Contacto"} />

                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getIButtonCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/iButton',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>
                                        <ListItemIcon>
                                            {this.state.itemListToView[0] !== undefined ? this.state.itemListToView[0].checkControl.iButton === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.itemListToView[0].checkControl.iButton === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                                : null
                                            }

                                        </ListItemIcon>
                                        <ListItemText primary={"iButton"} />

                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getTemperatureCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/temperatura',
                                        // state: this.props.location.state !== undefined ? this.props.location.state : "",
                                        // companyName: this.state.companyName
                                    }}>
                                        <ListItemIcon>
                                            {this.state.itemListToView[0] !== undefined ? this.state.itemListToView[0].checkControl.temperature === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.itemListToView[0].checkControl.temperature === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                                : null
                                            }

                                        </ListItemIcon>
                                        <ListItemText primary={"Temperatura"} />

                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getLocationCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/localizacion',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.location === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.location === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Localización" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                                    <ListItem onClick={this.getCanBusCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                        pathname: '/instaladores/checkeo/can-bus',

                                    }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.fms === 1 ? (
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                            ) : this.state.verificationsOptions.fms === 0 ? (
                                                <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                            ) : this.state.verificationsOptions.fms === 3 ? (
                                                <ErrorIcon style={{ color: "#32a852" }} />
                                            ) : this.state.verificationsOptions.fms === 2 ? (
                                                <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            ) : (
                                                <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Fms/Can-Bus" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

                                    <ListItem
                                        onClick={this.getTacografoCheck.bind(this)} button component={Link} transition='glide-left' to={{
                                            pathname: '/instaladores/checkeo/tacografo',

                                        }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.tacografo === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.tacografo === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="Tacógrafo" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

                                    <ListItem
                                        onClick={this.getLvcanCodec12Check.bind(this)}
                                        button component={Link} transition='glide-left' to={{
                                            pathname: '/instaladores/checkeo/lvCan',

                                        }}>
                                        <ListItemIcon>

                                            {this.state.verificationsOptions.lvcan === 1 ?
                                                <ReportProblemRoundedIcon style={{ color: "#32a852" }} />
                                                :
                                                this.state.verificationsOptions.lvcan === 0 ?
                                                    <CancelRoundedIcon style={{ color: "#eb4034" }} />
                                                    :
                                                    <CheckCircleRoundedIcon style={{ color: "#32a852" }} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText id="switch-list-label-wifi" primary="LvCan" />


                                        <ArrowForwardIosOutlinedIcon color="action" />
                                    </ListItem>
                                    <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                        <Grid container justifyContent="center" spacing={1}>
                                            {/* <Grid item>
                                                <p style={{ marginTop: "30px" }}>Inicio: </p>
                                                <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p>
                                            </Grid>
                                            <Grid>
                                                {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].startCheckControlDateUtc)}
                                            </Grid> */}
                                            <Grid item xs={3}>
                                                <p style={{ marginTop: "9px" }}>Inicio: </p>

                                            </Grid>
                                            <Grid item xs={9}>
                                                {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].startCheckControlDateUtc)}
                                                {/* <p style={{ marginTop: "15px" }}>Fin: {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}</p> */}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <p style={{ marginTop: "9px" }}>Fin: </p>

                                            </Grid>
                                            <Grid item xs={9}>
                                                {this.isEmpty(this.state.itemListToView) ? "" : this.formatDate(this.state.itemListToView[0].endCheckControlDateUtc)}
                                                {/* <p style={{ marginTop: "15px" }}>Fin: </p> */}
                                            </Grid>
                                            <Grid item xs={12}>

                                                <p style={{ margin: "0px", textAlign: "center" }}></p>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <div style={{ margin: "auto", width: "231px" }}>

                                        <p style={{ textAlign: "center" }}></p>

                                    </div> */}
                                </List>
                            </Grid>

                        </Route>

                        <Route exact path="/dashboard/añadir/" >
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List style={{ overflowY: "scroll" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <ListItem button component={Link} transition='glide-right' to={{
                                        pathname: '/dashboard/añadir/',
                                    }}>

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </ListItem>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Añadir nuevo</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>
                                {/* <Snackbar

                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    open={this.state.snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={this.handleClose.bind(this)}
                                    message={this.state.message}
                                    action={
                                        <React.Fragment>
                                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose.bind(this)}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </React.Fragment>
                                    }
                                /> */}
                            </Grid>
                            <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                                <TextField
                                    style={{ fontSize: "14px" }}
                                    id="outlined-number"
                                    // label="Número de serie"
                                    placeholder="Número de serie"
                                    type="number"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    variant="outlined"
                                    onChange={this.handleOnChange.bind(this)}
                                    inputProps={{ inputMode: 'numeric' }}
                                />
                                <TextField
                                    style={{ marginTop: "10px", fontSize: "14px" }}
                                    id="outlined-number"
                                    inputProps={{ maxLength: 40 }}
                                    // label="Número de serie"
                                    placeholder="Alias"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    variant="outlined"
                                    onChange={this.handleOnChangeAlias.bind(this)}
                                />
                                <div style={{ margin: "auto", width: "180px" }}>
                                    <Button style={{ width: "180px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined" onClick={this.addNewRegisterSerialNumber.bind(this)}>Añadir</Button>
                                    {/* <Link
                                    component="button"
                                    style={{border:"1px solid rgba(0, 0, 0, 0.23)", font:"Roboto", height: "27px", width: "130px", borderRadius:"15px", background: "#fff", fontSize: "12px", marginTop:"40px", letterSpacing: "0.02857em"}}
                                    variant="body2"
                                    onClick={this.verifySerialNumber.bind(this)}
                                >
                                    Verificar
                                </Link> */}
                                </div>
                            </div>

                        </Route>

                        <Route exact path="/instaladores/checkeo/contact"
                        // screen // shorthand to wrap children with screen

                        >
                            {!this.isEmpty(this.state.itemListToView) ?
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.contactView()}
                                    </div>

                                </Grid>

                                :
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <PullToRefresh
                                        style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                                        direction="down"
                                        refreshing={this.state.refreshing}
                                        // onRefresh={() => {
                                        //     this.setState({ refreshing: true })
                                        //     setTimeout(() => {
                                        //         this.setState({ refreshing: false })
                                        //     }, 1000)
                                        // }}
                                        onRefresh={this.getIgnitionKeyCheck.bind(this)}
                                        indicator={
                                            {
                                                activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                                                deactivate: <div></div>,
                                                release: <div><CircularProgress color="secondary" /></div>,
                                                finish: <div></div>
                                            }
                                        }

                                    >
                                        {this.contactView()}
                                    </PullToRefresh>

                                </Grid>

                            }




                            {/* <Link to="/instaladores/" transition='glide-right'>
                            Back to home page
                        </Link> */}
                            {/* <FormCheckControl /> */}
                        </Route>

                        <Route exact path="/instaladores/checkeo/inputDigital">
                            {!this.isEmpty(this.state.itemListToView) ? (
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.InputDigitalView()}
                                    </div>
                                </Grid>
                            ) : (
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    {this.InputDigitalView()}
                                </Grid>
                            )}
                        </Route>

                        <Route exact path="/instaladores/checkeo/outputDigital">
                            {!this.isEmpty(this.state.itemListToView) ? (
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.OutputDigitalView()}
                                    </div>
                                </Grid>
                            ) : (
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    {this.OutputDigitalView()}
                                </Grid>
                            )}
                        </Route>

                        <Route exact path="/instaladores/checkeo/inputDigital/update">
                            <Grid style={{ margin: "auto", height: "100%", overflowY: 'scroll' }} item xs={12} md={6}>
                                <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                    <div style={this.isEmpty(this.state.itemListToView) ? { height: "100%" } : { height: "70px" }}>
                                        <Grid item xs={12} md={12}>
                                            <ListItem className="listItem" button component={Link} transition='glide-right' to={{
                                                pathname: '/instaladores/checkeo/inputDigital',
                                            }}>
                                                <ListItemIcon>
                                                    <ArrowBackIosRoundedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Actualizar entradas digitales" style={{ marginRight: "10px" }} />
                                            </ListItem>
                                        </Grid>
                                        <div style={{ width: "95%" }}>
                                            <TextField
                                                className={classes.formControl}
                                                placeholder="Sensor"
                                                variant="outlined"
                                                size="medium"
                                                onChange={this.handleOnChangeSondaTemperatura.bind(this)}
                                                error={this.state.errorSonda}
                                                id="outlined-error"
                                                helperText={this.state.errorSondaText}
                                                required
                                                margin="none"
                                                inputProps={{ maxLength: 12 }}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100px" }}>
                                            <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem", marginBottom: "5px" }}
                                                //disabled={this.state.boolDisabledButtonSonda} 
                                                onClick={this.saveDataSondaTemperature.bind(this, 1)} variant="outlined">Actualizar sensor</Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>


                        </Route>

                        <Route exact path="/instaladores/checkeo/iButton"
                        // screen // shorthand to wrap children with screen

                        >
                            {!this.isEmpty(this.state.itemListToView) ?
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.iButtonView()}
                                    </div>

                                </Grid>

                                :
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <PullToRefresh
                                        style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                                        direction="down"
                                        refreshing={this.state.refreshing}
                                        // onRefresh={() => {
                                        //     this.setState({ refreshing: true })
                                        //     setTimeout(() => {
                                        //         this.setState({ refreshing: false })
                                        //     }, 1000)
                                        // }}
                                        onRefresh={this.getIButtonCheck.bind(this)}
                                        indicator={
                                            {
                                                activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                                                deactivate: <div></div>,
                                                release: <div><CircularProgress color="secondary" /></div>,
                                                finish: <div></div>
                                            }
                                        }


                                    >
                                        {this.iButtonView()}
                                    </PullToRefresh>

                                </Grid>

                            }


                            {/* <Link to="/instaladores/" transition='glide-right'>
                            Back to home page
                        </Link> */}
                            {/* <FormCheckControl /> */}
                        </Route>

                        <Route exact path="/instaladores/checkeo/temperatura"
                        // screen // shorthand to wrap children with screen

                        >
                            {!this.isEmpty(this.state.itemListToView) ?
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.temperatureView()}
                                    </div>

                                </Grid>

                                :
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <PullToRefresh
                                        style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                                        direction="down"
                                        refreshing={this.state.refreshing}
                                        // onRefresh={() => {
                                        //     this.setState({ refreshing: true })
                                        //     setTimeout(() => {
                                        //         this.setState({ refreshing: false })
                                        //     }, 1000)
                                        // }}
                                        onRefresh={this.getTemperatureCheck.bind(this)}
                                        indicator={
                                            {
                                                activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                                                deactivate: <div></div>,
                                                release: <div><CircularProgress color="secondary" /></div>,
                                                finish: <div></div>
                                            }
                                        }

                                    >
                                        {this.temperatureView()}
                                    </PullToRefresh>

                                </Grid>

                            }
                        </Route>

                        <Route exact path="/instaladores/checkeo/localizacion"
                        // screen // shorthand to wrap children with screen

                        >
                            {!this.isEmpty(this.state.itemListToView) ?
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.localizationView()}
                                    </div>

                                </Grid>

                                :
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <PullToRefresh
                                        style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                                        direction="down"
                                        refreshing={this.state.refreshing}
                                        // onRefresh={() => {
                                        //     this.setState({ refreshing: true })
                                        //     setTimeout(() => {
                                        //         this.setState({ refreshing: false })
                                        //     }, 1000)
                                        // }}
                                        onRefresh={this.getLocationCheck.bind(this)}
                                        indicator={
                                            {
                                                activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                                                deactivate: <div></div>,
                                                release: <div><CircularProgress color="secondary" /></div>,
                                                finish: <div></div>
                                            }
                                        }

                                    >
                                        {this.localizationView()}
                                    </PullToRefresh>

                                </Grid>

                            }
                        </Route>

                        <Route exact path="/instaladores/checkeo/can-bus"
                        // screen // shorthand to wrap children with screen

                        >
                            {!this.isEmpty(this.state.itemListToView) ?
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <div style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}>
                                        {this.canbusView()}
                                    </div>

                                </Grid>

                                :
                                <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                                    <PullToRefresh
                                        style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                                        direction="down"
                                        refreshing={this.state.refreshing}
                                        // onRefresh={() => {
                                        //     this.setState({ refreshing: true })
                                        //     setTimeout(() => {
                                        //         this.setState({ refreshing: false })
                                        //     }, 1000)
                                        // }}
                                        onRefresh={this.getCanBusCheck.bind(this)}
                                        indicator={
                                            {
                                                activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                                                deactivate: <div></div>,
                                                release: <div><CircularProgress color="secondary" /></div>,
                                                finish: <div></div>
                                            }
                                        }

                                    >
                                        {this.canbusView()}
                                    </PullToRefresh>

                                </Grid>

                            }

                        </Route>

                        <Route exact path='/instaladores/checkeo/tacografo'>
                            <CheckTachograph onHandleOnclickBackButton={this.onHandleOnclickBackButton} getTacografoCheck={this.getTacografoCheck} getTacografoSend={this.getTacografoSend} listTacografo={this.state.listTacografo} responseEvents={this.state.responseEvents} verificationsOptions={this.state.verificationsOptions} verifyState={this.verifyState} valueState={this.state.valueState} handleOnChangeTacografo={this.handleOnChangeTacografo} itemListToView={this.state.itemListToView} handleOnChangeTacografoDownload={this.handleOnChangeTacografoDownload} tachoDownload={this.state.tachoDownload} getTacografoCodec12Send={this.getTacografoCodec12Send} getTacografoCodec12Check={this.getTacografoCodec12Check} IsDeviceTachographDownloadEnabled={this.state.IsDeviceTachographDownloadEnabled} />
                        </Route>

                        <Route exact path='/instaladores/checkeo/lvCan'>
                            <LvCan onHandleOnclickBackButton={this.onHandleOnclickBackButton} getLvcanCodec12Check={this.getLvcanCodec12Check} listLvcan={this.state.listLvcan} responseEvents={this.state.responseEvents} setValueTextFieldLvCan={this.setValueTextFieldLvCan} getTacografoCodec12Send={this.getTacografoCodec12Send} verificationsOptions={this.state.verificationsOptions} verifyState={this.verifyState} valueState={this.state.valueState} handleOnChangeLvcan={this.handleOnChangeLvcan} lvCanButtonIsDisable={this.state.lvCanButtonIsDisable} itemListToView={this.state.itemListToView} />
                        </Route>

                        <Route exact path='/dashboard/historico/'>
                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List style={{ overflowY: "scroll" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <div>

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </div>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Histórico</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>
                                <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                                    <label>Inicio</label>
                                    <DatePicker
                                        onChange={this.onHandleChangeDatePickerStart.bind(this)}
                                        value={this.state.historicalDateStart}
                                        format={"dd-MM-yyyy"}
                                        // minDate={new Date()}
                                        clearIcon={null}
                                        locale={"es-ES"}
                                    />
                                    <label style={{ marginTop: "15px" }}>fin</label>
                                    <DatePicker
                                        onChange={this.onHandleChangeDatePickerEnd.bind(this)}
                                        value={this.state.historicalDateEnd}
                                        format={"dd-MM-yyyy"}
                                        // minDate={new Date()}
                                        clearIcon={null}
                                        locale={"es-ES"}
                                    />
                                    <label style={{ marginTop: "15px" }}>Buscar por: </label>
                                    <FormControl variant="outlined" className={classes.formControlHistorical}>
                                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.valueComboHistorical}
                                            placeholder="Buscar por:"
                                            onChange={this.handleChangeComboBoxHistorical.bind(this)}
                                        >
                                            {/* <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem> */}
                                            <MenuItem value={1}>Fecha solicitud</MenuItem>
                                            <MenuItem value={2}>Fecha inicio chequeo</MenuItem>
                                            <MenuItem value={3}>Fecha fin chequeo</MenuItem>
                                        </Select>
                                    </FormControl>


                                    <label style={{ marginTop: "15px" }}>Nº Serie</label>
                                    <TextField
                                        id="outlined-number"

                                        // label="Número de serie"
                                        placeholder="Número de serie"
                                        type="number"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        variant="outlined"
                                        onChange={this.handleOnChangeSerialNumberHistorical.bind(this)}
                                        inputProps={{ inputMode: 'numeric' }}
                                    />
                                    {this.state.valueComboHistorical !== "" && this.state.historicalSerialNumber !== "" ?
                                        <div style={{ width: "180px", margin: "auto" }}>
                                            < Button style={{ width: "180px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined" onClick={this.getHistorical.bind(this)}>Aceptar</Button>
                                        </div>

                                        :
                                        null
                                    }

                                    {this.state.redirectHistory ?
                                        <Redirect transition='glide-right' to="/dashboard/historico/listado" />
                                        : null
                                    }

                                </div>

                            </Grid>

                        </Route>

                        <Route exact path='/dashboard/configuracion-dispositivos/'>
                            <DeviceTypeConfiguration handleCloseSnackbarSuccess={this.handleCloseSnackbarSuccess} isOpenSnackbarSuccess={this.state.isOpenSnackbarSuccess} textSnackBarSuccess={this.state.textSnackBarSuccess} typesDevicesConfiguration={this.state.typesDevicesConfiguration} getDataTypeDevicesTopassConfiguration={this.getDataTypeDevicesTopassConfiguration} getDataTopassConfigurationDevices={this.getDataTopassConfigurationDevices} />
                        </Route>

                        <Route exact path='/dashboard/añadir-configuracion-dispositivos/'>
                            <FormAddDeviceConfiguration parametersDevicesConfiguration={this.state.parametersDevicesConfiguration} typesDevicesConfiguration={this.state.typesDevicesConfiguration} deviceConfigurationSelected={this.state.deviceConfigurationSelected} getDataTopassConfigurationDevices={this.getDataTopassConfigurationDevices} />
                        </Route>

                        <Route exact path='/dashboard/esquemas-canbus/'>

                            <Grid style={{ margin: "auto" }} item xs={12} md={6}>
                                <List style={{ overflowY: "scroll" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <ListItem button component={Button} transition='glide-right'
                                    >

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </ListItem>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Esquemas CANBus</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>

                                <SchemeCANBus parametersDevicesConfiguration={this.state.parametersDevicesConfiguration} typesDevicesConfiguration={this.state.typesDevicesConfiguration} deviceConfigurationSelected={this.state.deviceConfigurationSelected} getDataTopassConfigurationDevices={this.getDataTopassConfigurationDevices} />
                            </Grid>

                        </Route>

                        <Route exact path='/dashboard/gestion-manuales/'>
                            <ManagementManuals />
                        </Route>

                        <Route exact path='/dashboard/ordenes-trabajo/'>

                            <Grid style={{ margin: "auto", marginTop: 15 }} item xs={12} md={6}>
                                <List style={{ overflowY: "hidden" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <ListItem button component={Button} transition='glide-right'
                                    >

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </ListItem>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Mis OTs</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>

                                <TaskOrders {...this.props} parametersDevicesConfiguration={this.state.parametersDevicesConfiguration} typesDevicesConfiguration={this.state.typesDevicesConfiguration} deviceConfigurationSelected={this.state.deviceConfigurationSelected} getDataTopassConfigurationDevices={this.getDataTopassConfigurationDevices} />
                            </Grid>

                        </Route>

                        <Route exact path='/dashboard/ordenes-trabajo/detail'>

                            <Grid style={{ margin: "auto", marginTop: 15 }} item xs={12} md={6}>
                                <List style={{ overflowY: "hidden" }} subheader={<ListSubheader>
                                    {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}

                                    <ListItem button component={Button} transition='glide-right'
                                    >

                                        <ListItemIcon>

                                            <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" />

                                        </ListItemIcon>

                                    </ListItem>
                                    <p style={{ position: "absolute", top: "13px", right: "22px" }}> Mis OTs</p>

                                </ListSubheader>
                                } className={classes.root2}>
                                </List>

                                <TaskOrderDetail parametersDevicesConfiguration={this.state.parametersDevicesConfiguration} typesDevicesConfiguration={this.state.typesDevicesConfiguration} deviceConfigurationSelected={this.state.deviceConfigurationSelected} getDataTopassConfigurationDevices={this.getDataTopassConfigurationDevices} />
                            </Grid>

                        </Route>
                    </Navigation>
                </Router >
                {this.state.isLoading ?
                    <div className="loadingSpinner">
                        <BeatLoader css={spinnerCss} size={15} margin={4} color={"#bf2349"} />
                    </div>
                    :
                    null
                }

                <Snackbar

                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={3000}
                    auto
                    onClose={this.handleClose.bind(this)}
                    message={this.state.message}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose.bind(this)}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
            </div >

        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(InstaladoresCheckControl));
