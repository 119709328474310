import React, { Component } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, IconButton, Box, colors } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { Link } from "react-tiger-transition";
import Button from '@material-ui/core/Button';

const styles = theme => ({
  card: {
    maxWidth: "100%",
    marginTop: theme.spacing(2),
    height: '100%',
  },
  list: {
    maxHeight: '200px', // Ajusta esta altura según sea necesario
    overflowY: 'auto',
    paddingLeft: '0px !important'
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0, 
    paddingRight: 0
  },
  statusText: {
    fontWeight: 'bold',
  }
});

class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonDisabled: false, // Estado para deshabilitar el botón
      countdown: 0, // Cuenta atrás en segundos
    };
  }

  // handleActivate = (sensorId, countdown, isButtonDisabled) => {
  //   this.props.handleOnclickOutputDigitalUpdateSensors(sensorId);
  //   // Desactiva el botón y establece la cuenta atrás en 60 segundos
  //   this.setState({ isButtonDisabled: true, countdown: 120 });

  //   // Configura un intervalo para actualizar la cuenta atrás
  //   this.timer = setInterval(() => {
  //     this.setState((prevState) => {
  //       if (prevState.countdown <= 1) {
  //         clearInterval(this.timer); // Limpia el intervalo cuando llegue a 0
  //         return { isButtonDisabled: false, countdown: 0 }; // Reactiva el botón
  //       }
  //       return { countdown: prevState.countdown - 1 }; // Reduce el contador
  //     });
  //   }, 1000);
  // };

  componentWillUnmount() {
    // Limpia el temporizador si el componente se desmonta
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  formatDate(date) {
    if (!date) return "-/-/-";
    let time = new Date(date);
    let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
    let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
    let hour = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours());
    let minutes = (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
    let seconds = (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds());
    return day + "/" + month + "/" + time.getFullYear() + " " + hour + ":" + minutes + ":" + seconds;
  }

  getStatus(lastEvent) {
    if (!lastEvent) return "";
    const { startDate, endDate } = lastEvent;
    if (startDate && endDate) {
      return "Cerrado";
    } else if (startDate && !endDate) {
      return "Abierto";
    } else {
      return "";
    }
  }

  render() {
    const { classes, title, items, sensorId, handleOnclickInputDigitalUpdateSensors, getOutputDigitalCheck, handleActivate, isButtonDisabled, countdown } = this.props;

    const lastEvent = items.length > 0 ? items[items.length - 1] : null;

    const status = this.getStatus(lastEvent);

    return (
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {/* Título */}
            <Typography
              style={{
                maxWidth: '250px',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                hyphens: 'auto',
              }}
              variant="h5"
            >
              {title}
            </Typography>

            <Box display="flex" alignItems="center">
              {this.props.title.includes("DOUT") && (
                <Button
                  variant="outlined"
                  onClick={() => handleActivate(sensorId)}
                  style={{ marginRight: '8px' }}
                  disabled={isButtonDisabled} // Deshabilita el botón cuando está activado
                >
                  {/* {isButtonDisabled ? `${countdown} seg` : 'Activar'} */}
                  {/* Muestra el tiempo de cuenta atrás o 'Activar' */}
                  {isButtonDisabled && countdown !== "Activar"
                    ? `${countdown} seg`
                    : 'Activar'}
                </Button>
              )}

              {/* Botón de sincronización existente */}
              {this.props.sensorId === 'din2' ? (
                <IconButton
                  onClick={() => handleOnclickInputDigitalUpdateSensors(sensorId)}
                  component={Link}
                  transition="glide-left"
                  to={{
                    pathname: '/instaladores/checkeo/inputDigital/update',
                  }}
                >
                  <SyncAltIcon />
                </IconButton>
              ) : this.props.title.includes("DOUT") ?
                <IconButton
                  onClick={() => getOutputDigitalCheck(sensorId)}
                //component={Link}
                //transition="glide-left"
                // to={{
                //   pathname: '/instaladores/checkeo/inputDigital/update',
                // }}
                >
                  <SyncAltIcon />
                </IconButton>
                : null}
            </Box>
          </Box>
          {this.props.title.includes("DOUT") &&
            items.length > 0 &&
            items[items.length - 1] &&
            items[0].endDate === null ?
            (
              <Typography variant="body1" className={classes.statusText}>
                <span>Estado: </span>
                <span style={{ color: '#32a852', fontWeight: 'bold', marginLeft: 5 }}>Activado</span>
              </Typography>
            ) : this.props.title.includes("DOUT") ? <Typography variant="body1" className={classes.statusText}>
              <span>Estado: </span>
              <span style={{ color: '#eb4034', fontWeight: 'bold', marginLeft: 5 }}>Desactivado</span>
            </Typography>
              : null
          }


          {items.length > 0 && this.props.sensorId.includes("din") && status && (
            <Typography variant="body1" className={classes.statusText}>
              Estado: {status}
            </Typography>
          )
          }
          <List className={classes.list}>
            {items.length > 0 ? items.map((item, index) => (
              <ListItem disableGutters={true} key={index} className={classes.listItem} >
                <ListItemText
                  primary={
                    this.props.title.includes("DOUT") ? (
                      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '8px', rowGap: '4px' }}>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Activación:</Typography>
                        <Typography variant="body1">{this.formatDate(item.startDate)}</Typography>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Desactivación:</Typography>
                        <Typography variant="body1">{this.formatDate(item.endDate)}</Typography>
                      </div>
                    ) : (
                      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '8px', rowGap: '4px' }}>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Inicio:</Typography>
                        <Typography variant="body1">{this.formatDate(item.startDate)}</Typography>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Fin:</Typography>
                        <Typography variant="body1">{this.formatDate(item.endDate)}</Typography>
                      </div>
                    )
                  }
                />
              </ListItem>
            )) : null}
          </List>



        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CardList);
