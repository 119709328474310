import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from "react-tiger-transition";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import PullToRefresh from 'rmc-pull-to-refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = theme => ({
    root2: {
        width: '100%',
        paddingTop: "15px",
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        '& .MuiTextField-root2': {
            margin: theme.spacing(1),
            width: '25ch',
        }
    },
    listFont: {
        '& .MuiListItemText-secondary': {
            fontSize: theme.typography.fontSize,
        },
        '& .MuiListItemText-primary': {
            fontSize: "16px !important"
        }
    },
});



class LvCan extends Component {

    state = {
        lvCanButtonIsDisable: true,
        valueTextFieldLvCan: "",
        helperTextErr: "",
        textFieldErr: false,
        refreshing: false,
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    handleOnChangeTextFieldCode(e) {
        var reg = /^[0-9]+$/;

        if (reg.test(e.target.value)) {
            this.setState({
                valueTextFieldLvCan: e.target.value,
            }, () => {
                this.props.setValueTextFieldLvCan(this.state.valueTextFieldLvCan)
            });
        } else if (e.target.value === "") {
            this.setState({
                valueTextFieldLvCan: ""
            })
        }
        if (e.target.value.length > 5) {
            this.setState({
                helperTextErr: "Longitud máxima de 5 digitos.",
                textFieldErr: true,
                lvCanButtonIsDisable: true
            })
        } else if (e.target.value.length === 5) {
            this.setState({
                lvCanButtonIsDisable: false,
                textFieldErr: false,
                helperTextErr: ""
            })
        } else {
            this.setState({
                helperTextErr: "",
                textFieldErr: false,
                lvCanButtonIsDisable: true
            })
        }
        // else {
        //     
        // }


    }
    onHandleOnClickChangeState() {
        this.props.verifyState();
    }
    handleOnClickSendCode = () => {
        this.props.getTacografoCodec12Send(`lvcansetprog%20${this.state.valueTextFieldLvCan}`);
    }

    refreshGetLvcanCodec12Check = () => {
        this.setState({
            refreshing: true
        }, async () => {
            await this.props.getLvcanCodec12Check();
        });

        this.setState({
            refreshing: false
        })

    }

    onHandleOnclickBackButtonlvCan = () => {
        this.props.onHandleOnclickBackButton();
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid style={{ margin: "auto", height: "100%" }} item xs={12} md={6}>
                <PullToRefresh
                    style={{ height: "100%", overflowY: 'auto', overflowX: "hidden" }}
                    direction="down"
                    refreshing={this.state.refreshing}
                    onRefresh={this.refreshGetLvcanCodec12Check.bind(this)}
                    indicator={
                        {
                            activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                            deactivate: <div></div>,
                            release: <div><CircularProgress color="secondary" /></div>,
                            finish: <div></div>
                        }
                    }

                >
                    <div style={{ height: "100%" }}>
                        <List subheader={<ListSubheader>
                            {/* <img src="https://staticcontent.inelcan.com/img/4gflota_logo_new.png" style={{ height: "35px" }} alt="4GFlota" className="img-responsive" /> */}
                            {!this.isEmpty(this.props.itemListToView) ?
                                <ListItem onClick={this.onHandleOnclickBackButtonlvCan.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo/' + this.props.itemListToView[0].serialNumber,

                                }}>
                                    <ListItemIcon>

                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LvCan" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#introducir-c%C3%B3digo-del-lvcan200',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                    {/* <ListItemText primary="Verificación" /> */}
                                </ListItem>
                                :
                                <ListItem onClick={this.onHandleOnclickBackButtonlvCan.bind(this)} className="listItem" button component={Link} transition='glide-right' to={{
                                    pathname: '/dashboard/instaladores/checkeo',

                                }}>
                                    <ListItemIcon>

                                        <ArrowBackIosRoundedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LvCan" style={{ marginRight: "10px" }} />
                                    <IconButton edge="end" aria-label="delete">
                                        <HelpOutlineIcon
                                            style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation(); // Evita que se dispare el evento del ListItem
                                                window.open(
                                                    'https://ayudawiki.4gflota.com/es/instalaciones#introducir-c%C3%B3digo-del-lvcan200',
                                                    '_blank'
                                                ); // Reemplaza con tu enlace
                                            }}
                                        />
                                    </IconButton>
                                    {/* <ListItemText primary="Verificación" /> */}
                                </ListItem>
                            }


                            {/* <p style={{ position: "absolute", top: "4px", right: "22px" }}> Verificación</p> */}

                        </ListSubheader>
                        } className={classes.root2}>
                        </List>

                        <div style={{ width: "320px", margin: "auto", marginTop: "20px" }}>
                            {this.props.responseEvents.length < 1 ?
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ textAlign: "left" }}
                                        value={this.isEmpty(this.props.itemListToView) ? this.props.valueState : this.props.itemListToView[0].checkControl.lvcan}
                                        placeholder="Selecciona un estado"
                                        onChange={this.props.handleOnChangeLvcan.bind(this)}
                                        disabled={!this.isEmpty(this.props.itemListToView) ? true : false}
                                    >
                                        {/* <MenuItem value={0}>Sin Seleccionar</MenuItem> */}
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        {/* <MenuItem value={2}>Verificado</MenuItem> */}
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl}>
                                    <Select
                                        style={{ textAlign: "left" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.props.valueState}
                                        placeholder="Selecciona un estado"
                                        onChange={this.props.handleOnChangeLvcan.bind(this)}
                                        disabled={this.props.responseEvents.length >= 1 ? true : false}
                                    >
                                        <MenuItem value={"sinSeleccionar"}>Sin Seleccionar</MenuItem>
                                        <MenuItem value={1}>No aplica</MenuItem>
                                        <MenuItem value={2}>Verificado</MenuItem>
                                        <MenuItem value={0}>Pendiente</MenuItem>
                                    </Select>
                                </FormControl>

                            }
                            {/* <FormControl style={{ marginTop: "0px", width: "100%", wordWrap: "break-word", marginBottom: 10 }} variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: "left" }}
                                    value={this.isEmpty(this.props.itemListToView) ? this.props.valueState : this.props.itemListToView[0].checkControl.lvcan}
                                    placeholder="Selecciona un estado"
                                    onChange={this.props.handleOnChangeLvcan.bind(this)}
                                    disabled={!this.isEmpty(this.props.itemListToView) ? true : false}
                                >
                                    {/* <MenuItem value={0}>Sin Seleccionar</MenuItem> 
                            <MenuItem value={1}>No aplica</MenuItem>
                            <MenuItem value={2}>Verificado</MenuItem>
                            <MenuItem value={0}>Pendiente</MenuItem>
                                </Select>
                            </FormControl> */}
                            {this.isEmpty(this.props.itemListToView) ?
                                <React.Fragment>
                                    <TextField
                                        // style={{ fontSize: "14px" }}
                                        id="outlined-number"
                                        // label="Número de serie"
                                        placeholder="Enviar código"
                                        type="number"
                                        value={this.state.valueTextFieldLvCan}
                                        className={classes.textField}

                                        helperText={this.state.helperTextErr}
                                        error={this.state.textFieldErr}
                                        variant="outlined"
                                        onChange={this.handleOnChangeTextFieldCode.bind(this)}
                                        inputProps={{ inputMode: 'numeric', input: classes.resize, style: { fontSize: 14 } }} // font size of input text
                                        InputLabelProps={{ style: { fontSize: 14, marginTop: 0 } }}
                                        FormHelperTextProps={{ style: { fontSize: 12 } }}
                                    />
                                    <Button onClick={this.handleOnClickSendCode} disabled={this.state.lvCanButtonIsDisable} style={{ width: "180px", borderRadius: "20px", marginTop: "40px", fontSize: "1.3rem" }} variant="outlined">Enviar código</Button>
                                </React.Fragment>
                                : null
                            }

                        </div>

                        <Grid item xs={12} md={6} style={{ margin: 'auto' }}>

                            <div>
                                <List dense={true}>
                                    {this.props.listLvcan.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ListItem className={classes.listFont}>
                                                    <ListItemText
                                                        primary={item.codeCheck}
                                                    // secondary={'Secondary text'}
                                                    />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>

                                        )
                                    })}


                                </List>
                            </div>
                        </Grid>
                    </div>
                    {
                        this.props.verificationsOptions.lvcan !== 2 ?
                            this.isEmpty(this.props.itemListToView) ?
                                <div style={{ margin: "auto", width: "100%" }}>

                                    <Button style={{ width: "180px", borderRadius: "20px", fontSize: "1.3rem" }} variant="outlined" onClick={this.onHandleOnClickChangeState.bind(this)}>Cambiar estado</Button>
                                </div>
                                : null
                            : null
                    }
                </PullToRefresh >

            </Grid >

        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(LvCan));
